import { Button, ScrollBar } from "components/ui";
import { HiVideoCamera } from 'react-icons/hi'
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeCamerasURL, setCameraSelected, setWatchLive } from "../store/dataSlice";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { apiStartLiveCamera, apiStopLiveCamera } from "services/DevicesService";


const StoreCameras = () => {

    const EventIcon = ({ online }) => {

        const baseClass = 'rounded-lg h-10 w-10 text-lg flex items-center justify-center'

        if (online) {
            return <div className={classNames(baseClass, 'bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100')} ><HiVideoCamera /></div>
        } else {
            return <div className={classNames(baseClass, 'bg-red-100 text-red-600 dark:bg-red-500/20 dark:text-red-100')} ><HiVideoCamera /></div>
        }
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { cameras, query } = useSelector(store => store.camerasStoreData.data)
    const { storeCameras, cameraSelected } = cameras


    const handleSelectCamera = (camera) => {
        apiStopLiveCamera(cameraSelected.id)
        apiStartLiveCamera(camera?.id)
        dispatch(setCameraSelected(camera))
        dispatch(setWatchLive(true))
        navigate(makeCamerasURL({ ...query, device: camera.id }))
    }

    return (
        <>
            <h5 className="mb-4">{t('cameras_capitalize')}</h5>
            <div className="h-[50px] sm:h-[200px]">
                <ScrollBar autoHide className="h-full">
                    {storeCameras && storeCameras.length > 0 && storeCameras.map(camera => (
                        <div
                            key={camera.id}
                            className="flex items-center justify-between rounded-md mb-2 hover:bg-gray-50 dark:hover:bg-gray-600/40 cursor-pointer user-select"
                        >
                            <div className="flex items-center gap-3">
                                <EventIcon online={camera.online} />
                                <div>
                                    <h6 className="text-sm font-bold">{camera.location}</h6>
                                    <p>{camera.online ? 'Online' : 'Offline'}</p>
                                </div>
                            </div>
                            {true &&
                                <Button
                                    size="sm"
                                    variant="solid"
                                    onClick={() => handleSelectCamera(camera)}
                                >
                                    {t('live')}
                                </Button>
                            }
                        </div>
                    ))}
                </ScrollBar>
            </div>
        </>
    )
}

export default StoreCameras