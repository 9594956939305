import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    country: [],
    language: [], 
    timezone:[]
}


const dataSlice = createSlice({
    name: 'CountriesTimezonesLanguages',
    initialState,
    reducers: {
        setInformationData: (state, action) => {
            state.country = action.payload.country
            state.timezone = action.payload.timezone
            state.language = action.payload.language
        }
    }
})

export const { setInformationData } = dataSlice.actions

export default dataSlice.reducer 