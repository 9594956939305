import React, { useState } from 'react'
import { Button, FormItem, FormContainer, Notification, toast } from 'components/ui'
import { Field, Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { isMobile, jsonToFormData, renameFile, showMessageError } from 'utils/storeHube/helpers'
import { apiPostAnswerTicket, } from 'services/TicketsService'
import { getTicketById } from '../store/dataSlice'
import { statusResponse } from 'constants/apiResponse.constant'
import { useTranslation } from 'react-i18next'
import { apiGetStoreById } from 'services/StoreService'
import usePermissions from 'utils/hooks/usePermissions'
import { toggleTicketDialog } from '../store/stateSlice'
import { useEffect } from 'react'
import QuestionGenerator from '../ticketList/QuestionGenerator'
import { filterVisibleQuestions } from 'views/crud/formQuetionsGroup/helpers'
import { useSelector } from 'react-redux'
import { deleteKey, getKey, setKey } from 'utils/storeHube/localStorage'
import { TEXT, SELECTOR, CHECKBOX, RADIO, TEXTAREA, DATE, MENU, TITLE, FILE, NUMBER, OBJECT, STRING } from 'constants/app.constant'

const TicketQuestion = ({ questionGroupSecondary, questionSecondaryGroupId, ticketId, store, assigned, id, setNewTicketStatus, ticketStatus }) => {

    const { t } = useTranslation()
    const { checkPermission } = usePermissions()
    const dispatch = useDispatch()

    const QUESTION_SECONDARY_KEY = `question_group_secondary_${ticketId}`
    const google = window.google
    const userAgent = navigator.userAgent;
    const { ticketStores: stores } = useSelector(store => store.ticketList.global)

    const [answers, setAnswer] = useState([])
    const [answersCheckBox, setAnswersCheckBox] = useState([]);
    const [filesQuestions, setFilesQuestions] = useState([])
    const [myLocation, setMyLocation] = useState(false)
    const [storeLocation, setStoreLocation] = useState(false)
    const [distanceUser, setDistanceUser] = useState(null)
    const [deviceUser, setDeviceUser] = useState(null)
    const [secondaryQuestions, setSecondaryQuestions] = useState([])


    const [configForm, setConfigForm] = useState({
        initalValues: {},
        validationSchema: {},
        ready: false
    })


    useEffect(() => {
        if (questionGroupSecondary) {
            buildFormConfig()
        }
    }, [questionGroupSecondary])


    const buildFormConfig = () => {
        const response = filterVisibleQuestions(questionGroupSecondary.questions)
        if (response) {
            const initialValues = addPropertiesToInitialValues(response)
            const validation = dynamicValidationSchema(response)
            setConfigForm({
                initalValues: initialValues,
                validationSchema: validation,
                ready: true
            })
            setSecondaryQuestions(response)
        }
    }

    function addPropertiesToInitialValues(questions) {
        const newInitialValues = {};
        const formLs = getFormInLS()
        questions.map((question) => {
            let value = formLs[question.id]
            const defaultValue = question.question_type_id === 4 ? (value ?? "") : (value ?? undefined); // define un valor predeterminado basado en el tipo de pregunta
            const propertyName = question.id; // crea el nombre de propiedad basado en el id de la pregunta
            newInitialValues[propertyName] = defaultValue;
        });
        return newInitialValues
    }

    const dynamicValidationSchema = (data) => {
        const shapeObj = data.reduce((acc, item) => {

            let rules = Yup.string().max(400, 'El campo debe ser menor que 400 caracteres')

            if (item.question_type_id === TEXT || item.question_type_id === TEXTAREA) {
                acc[item.id] = rules
            }
            if (item.question_type_id === CHECKBOX || item.question_type_id === FILE) {
                rules = Yup.array()
                acc[item.id] = rules
            }
            if (item.required) {
                acc[item.id] = rules.required('Esta pregunta es obligatoria')
            }
            return acc;
        }, {});

        return Yup.object().shape(shapeObj);
    };


    const onUploadFilesQuestion = (file, id) => {
        const files = Array.isArray(file) ? file : [file]
        setFilesQuestions((prevState) => {
            let updatedFiles = [...prevState];
            // Verificar si ya existe un archivo con el mismo id
            const existingFileIndex = prevState.findIndex((item) => item.id === id);
            if (existingFileIndex !== -1) {
                // Reemplazar el archivo existente
                updatedFiles[existingFileIndex] = { id, files: renameFile(files) };
            } else {
                // Agregar un nuevo archivo
                updatedFiles = [...updatedFiles, { id, files: renameFile(files) }]
            }
            return updatedFiles
        });
        return files
    };

    const removeFileState = (file, id) => {
        let questionsFile = structuredClone(filesQuestions)
        const fileIndex = filesQuestions.findIndex((item) => item.id === id);
        let answerFile = questionsFile[fileIndex]
        const filesFilter = answerFile.files?.filter(f => f.lastModified !== file.lastModified)
        if (filesFilter.length < 1) {
            questionsFile = questionsFile.filter((q, index) => index !== fileIndex)
        } else {
            answerFile.files = filesFilter
        }
        setFilesQuestions(questionsFile)
        return filesFilter
    }

    const createAnswers = (response) => {
        const { question_id, id } = response;
        const objKey = { [question_id]: id }
        const responseObj = { ...answersCheckBox };
        const objFound = answersCheckBox.find(obj => JSON.stringify(obj) === JSON.stringify(objKey));
        if (objFound) {
            delete responseObj[objKey];
            const newanswersCheckBox = answersCheckBox.filter(obj => JSON.stringify(obj) !== JSON.stringify(objKey));
            setAnswersCheckBox(newanswersCheckBox)
        } else {
            responseObj[objKey] = { [question_id]: id };
            setAnswersCheckBox([...answersCheckBox, responseObj[objKey] = { [question_id]: id }]);
        }
    }

    const submitQuestions = () => {
        const arrayOfAnswersLS = [];

        try {
            /* if (answersCheckBox.length > 0) {
                answersCheckBox.forEach(function (obj) {
                    let key = Object.keys(obj)[0];
                    let value = obj[key];
                    let newObj = {
                        id: key,
                        question_option_id: value
                    };
                    arrayOfAnswers.push(newObj);
                });
            } */

            const formLS = getFormInLS()
            const formValues = Object.keys(formLS).map(key => ({
                key,
                value: formLS[key],
                type: typeof (formLS[key])
            }));

            formValues.map(answer => {

                if (answer.type === STRING) {
                    arrayOfAnswersLS.push({
                        id: answer.key,
                        value: answer.value.trim(),
                    })
                }
                if (answer.type === NUMBER) {
                    arrayOfAnswersLS.push({
                        id: answer.key,
                        question_option_id: answer.value
                    })
                }

                if (answer.type === OBJECT) {
                    answer.value.map(value => value && arrayOfAnswersLS.push({
                        id: answer.key,
                        question_option_id: value
                    }))
                }
            })


            const fileAnswer = filesQuestions.reduce((result, obj) => {
                const filesArray = obj.files.map(f => ({ id: obj.id.toString(), file: f }));
                return result.concat(filesArray);
            }, []);
            arrayOfAnswersLS.push(...fileAnswer)

        } catch (error) {
            console.log(error);
        }
        return arrayOfAnswersLS
    };



    const onSubmit = async (formValue, setSubmitting, resetForm) => {
        setSubmitting(true)
        const {latitude, longitude} = myLocation
            
        const answerQuestion = {
            question_group_id: questionSecondaryGroupId,
            questions: submitQuestions(),
            device: deviceUser,
            distance: distanceUser,
            ...(latitude !== undefined && { latitude: latitude }),
            ...(longitude !== undefined && { longitude: longitude }),
        };

        try {
            const { status } = await apiPostAnswerTicket(ticketId, jsonToFormData(answerQuestion))
            if (status === statusResponse.SUCCESS) {
                dispatch(getTicketById(ticketId))
                setSubmitting(false)
                deleteKey(QUESTION_SECONDARY_KEY)
                if (ticketStatus === 1) setNewTicketStatus(assigned, id)
                toast.push(
                    <Notification title={t('created')} type="success" duration={2500}>
                        {t('questionnaire_answered_correctly')}
                    </Notification>
                )
            }

        } catch (error) {
            setSubmitting(false)
            let apiError = error.response.data.message
            if (apiError === "You must send some answer") {
                apiError = "Debes enviar al menos una respuesta."
            }
            if (apiError === "Question group already sent") {
                apiError = "Cuestionario ya enviado"
            }
            showMessageError(apiError)
        }
        dispatch(toggleTicketDialog(false))
    }

    //fucnionalidades de geolocalizacion
    useEffect(() => {
        getStoreLocation()
        handleGetUserLocation()
    }, [store])


    useEffect(() => {
        if (myLocation && storeLocation) {
            handleLocation()
        }
    }, [myLocation, storeLocation])


    useEffect(() => {
        if (isMobile()) setDeviceUser("mobile");
        else setDeviceUser("desktop");
    }, [])


    const getStoreLocation = () => {
        if (store) {
            const foundStore = stores.find(s => s.id === store.id)
            if (foundStore && foundStore?.latitude && foundStore?.longitude) {
                setStoreLocation({ latitude: Number(foundStore?.latitude), longitude: Number(foundStore?.longitude) })
            } else {
                setStoreLocation(false)
            }
        }
    }

    const handleGetUserLocation = async () => {
        const { response, error } = await getUserLocation()
        if (error) {
            setDistanceUser(t("not_permits_geolocation"))
        }
        setMyLocation(response)
    }


    const getUserLocation = async () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (res) => {
                    resolve({
                        error: false,
                        response: {
                            latitude: res?.coords?.latitude,
                            longitude: res?.coords?.longitude
                        }
                    })
                },
                (err) => {
                    resolve({ error: true, response: false })
                }
            )
        })
    }

    const handleLocation = () => {
        const { latitude, longitude } = myLocation

        const origin = { lat: latitude, lng: longitude }
        const destination = { lat: storeLocation?.latitude, lng: storeLocation?.longitude };
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            travelMode: 'DRIVING',
        }, callbackDistanceMatrix);
    }


    const callbackDistanceMatrix = (response, status) => {
        try {
            if (status === 'OK') {
                const distanceToStore = response?.rows[0]?.elements[0]?.distance?.text
                setDistanceUser(distanceToStore);
            } else {
                setDistanceUser(t("error_distance"))
            }
        } catch (error) {
            setDistanceUser(t("error_distance"))
        }
    }


    const getFormInLS = () => {
        const ls = getKey(QUESTION_SECONDARY_KEY)
        if (ls) {
            return JSON.parse(ls)
        } else {
            setKey(QUESTION_SECONDARY_KEY, JSON.stringify({}))
            return {}
        }
    }

    const saveFormInLocalStorage = (data) => {
        const { question, type, value } = data

        let questionGroup = getFormInLS()
        let newValue = value

        if (questionGroup) {
            if (type === FILE) {
                return
            }
            if (type === CHECKBOX) {
                newValue = newValue ? JSON.parse(newValue) : undefined
            }
            questionGroup[question] = newValue
            setKey(QUESTION_SECONDARY_KEY, JSON.stringify(questionGroup))
        }
    }


    const setFilesValues = (question, values, type) => {
        let response = undefined
        if (type === 'change_files') {
            response = onUploadFilesQuestion(values, question)
        }
        if (type === 'remove_file') {
            response = removeFileState(values, question)
        }
        return response.length > 0 ? response : undefined
    }


    return (
        <div className='mt-6'>
            {configForm.ready &&
                <Formik
                    initialValues={configForm.initalValues}
                    validationSchema={configForm.validationSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        onSubmit(values, setSubmitting, resetForm);
                    }}
                >
                    {({ touched, errors, values, resetForm, isSubmitting }) => (
                        <div className='max-h-[35rem] overflow-auto'>
                            <Form className='m-4'>
                                <FormContainer>
                                    {secondaryQuestions && (
                                        <>
                                            <span className='form-label mb-2'>{secondaryQuestions?.name}</span>
                                            {secondaryQuestions.map((question) => (
                                                <FormItem
                                                    key={question.id}
                                                    //invalid={question.id.required && errors.question?.id}
                                                    errorMessage={errors[question.id]}
                                                >
                                                    <Field name={question.id}>
                                                        {({ field, form }) => (
                                                            <QuestionGenerator
                                                                value={configForm.initalValues[question.id]}
                                                                onChange={(val, type) => {

                                                                    let updatedAnswers = { ...answers };
                                                                    if ([TEXT, SELECTOR, RADIO, TEXTAREA, DATE].includes(question.question_type_id)) {
                                                                        updatedAnswers[question.id] = val;
                                                                    }
                                                                    setAnswer(updatedAnswers);

                                                                    if (question.question_type_id !== FILE && field.value === val) {
                                                                        form.setFieldValue(field.name, '');
                                                                    } else if (question.question_type_id === FILE) {
                                                                        const valueFiles = setFilesValues(question.id, val, type)
                                                                        form.setFieldValue(field.name, valueFiles);
                                                                    } else {
                                                                        form.setFieldValue(field.name, val);
                                                                    }
                                                                    saveFormInLocalStorage({ question: question.id, type: question.question_type_id, value: val })
                                                                }}
                                                                questions={question}
                                                                createAnswers={createAnswers}
                                                                errorMessage={errors[question.id]}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            ))}
                                        </>
                                    )}
                                    <Button block variant="solid" type="submit" loading={isSubmitting}>
                                        {isSubmitting ? t('sending') : t('reply_answer')}
                                    </Button>
                                </FormContainer>
                            </Form>
                        </div>
                    )}
                </Formik>
            }
        </div>
    );
}

export default TicketQuestion