import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from 'react-player'
import { TicketSection } from "./TicketContent";
import { HiVideoCamera } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Card, Notification, toast } from "components/ui";
import useThemeClass from "utils/hooks/useThemeClass";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { filterScheduleByRecord } from "views/cameras/store/dataSlice";



const TicketRecod = ({ record }) => {

    const { t } = useTranslation()
    const { textTheme } = useThemeClass()
    const navigate = useNavigate()

    const playerRef = React.useRef();

    const { selectedTicket } = useSelector(state => state.ticketList.data)
    const { parameters } = useSelector(state => state.auth.user)

    
    const baseUrlWbi = "https://s3.wasabisys.com/storehub-records/"
    const oldBaseUrlWbi = "https://s3.wasabisys.com/missioncontrol-records/records/"

    const IS_DEMO = window.location.hostname.includes('demo-ar')


    const [record_blurred, setTenantRecordBlurred] = useState(false)
    const [baseUrl, setBaseUrl] = useState(baseUrlWbi)

    const {
        id,
        blurred_exist,
        blurred_url,
        record_url,
        record_exist,
        file_at,
        second_start
    } = record


    const data = {
        id,
        url: record_blurred ? blurred_url : record_url,
        record_exist: record_blurred ? blurred_exist : record_exist,
        file_at: dayjs(file_at).format('YYYY-MM-DD'),
        second_start: selectedTicket.second_start || false,
        store: selectedTicket.props?.store,
        device: selectedTicket.props?.device,
        second_start: second_start || false
    }

    const onErrorUrl = () => {
        setBaseUrl(oldBaseUrlWbi)
    }

    useEffect(() => {
        if (parameters?.length > 0) {
            const { value } = parameters.find(p => p.key === "record_blurred")
            setTenantRecordBlurred(value === "true")
        }
    }, [parameters])


    const setTimeStart = (start) => {
        setTimeout(() => {
            playerRef.current.seekTo(start, 'seconds')
        }, 1000)
    }

    useEffect(() => {
        data.second_start && setTimeStart(data.second_start)
    }, [])


    const goToRecord = () => {
        const {
            store,
            device,
            file_at,
            id,
            second_start
        } = data

        const schedule = filterScheduleByRecord(record)
        if (store?.id && device?.id) {
            const url = `/cameras?store=${store.id}&device=${device.id}&date=${file_at}&record=${id}&seconds=${second_start}`
            window.open(url)
        } else {
            toast.push(
                <Notification title={'Error'} type="danger" duration={2500}>
                    <span>{t('not_found_path_to_record')}</span>
                </Notification>
            )
        }
    }

    const url_demo = `https://s3.us-east-1.wasabisys.com/storehub-records/stordemo/5.mp4#t=${data.second_start}`

    return (
        <TicketSection title={t('record')} icon={<HiVideoCamera />}>
            <div className="py-4 h-[200px] w-[230px]  md:h-[350px] md:w-[500px]">
                <Card bodyClass="p-4">
                    <ReactPlayer
                        controls
                        ref={playerRef}
                        url={IS_DEMO ? url_demo : baseUrl + data.url + `#t=${data.second_start}`}
                        width={"100%"}
                        height={"100%"}
                        onError={onErrorUrl}
                    />
                    <div className="flex justify-between mt-3 mb-2">
                        <div className="flex gap-3">
                            <span>
                                {data.store?.name}
                            </span>
                            <span className="font-semibold text-gray-900 dark:text-gray-100">
                                {data.device?.location}
                            </span>
                        </div>
                        <span
                            className={`cursor-pointer select-none font-semibold hover:${textTheme}`}
                            onClick={goToRecord}
                        >
                            {t('go_to_video')}
                        </span>
                    </div>
                </Card>
            </div>
        </TicketSection>
    )
}

export default TicketRecod