import { useDispatch } from "react-redux"
import { filterRecordsByHour, makeCamerasURL, setFilterHour, setMainRecord, setPageQuery, setPlayList } from "../store/dataSlice"
import { useSelector } from "react-redux"
import { Segment } from "components/ui"

import { useNavigate } from "react-router-dom"

export const optionHours = [
    { id: 1, key: 'morning', label: 'Mañana', from: "07:00", to: "13:59" },
    { id: 2, key: 'afternoon', label: 'Tarde', from: "14:00", to: "19:59" },
    { id: 3, key: 'night', label: 'Noche', from: "20:00", to: "23:59" },
    { id: 4, key: 'all', label: 'Todos', from: "00:00", to: "23:59" },
]


const SelectHour = ({ size = 'sm' }) => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { filter, query, records } = useSelector(store => store.camerasStoreData.data)
    const { list } = records

    const onChangeHour = (data) => {
        const hour = optionHours.find(h => h.id === data)
        const { from, to } = hour
        const newPlaylist = filterRecordsByHour(list, from, to)
        const record = newPlaylist[0]?.id ?? false
        dispatch(setFilterHour(hour))
        dispatch(setPlayList(newPlaylist))
        navigate(makeCamerasURL({ ...query, record: record, seconds: false }))
    }


    return (
        <Segment
            className="flex justify-center"
            onChange={d => onChangeHour(d[0])}
            size={size}
            value={[filter.hour.id]}
        >
            {optionHours.map(o => (
                <Segment.Item key={o.id} value={o.id} >{o.label}</Segment.Item>
            ))}
        </Segment>
    )
}
export default SelectHour