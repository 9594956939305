export const getDescriptionImages = async (data) => {


    const tempElement = document.createElement('div');
    tempElement.innerHTML = data;

    const imagenes = tempElement.querySelectorAll('img');
    imagenes.forEach((img) => img.parentNode.removeChild(img));

    const elements = tempElement.innerHTML;

    const files = Array.from(imagenes).map((img) => {
        const binaryData = atob(img.src.split(',')[1]);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'image/png' });
        var file = new File([blob], `${crypto.randomUUID()}.png`, { type: 'image/png' });
        return file
    });

    return { elements, files };
}