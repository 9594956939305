import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
    name:"calibration/data", 
    initialState:{
        loading: false, 
        update: false, 
        analyticsTickets: [], 
        countCalibrator: [], 
  
    },
    reducers:{
 
        setCountCalibratorStore: (state, action) => {
            state.countCalibrator = action.payload
        }, 
        setAnalyticsTicketsStore: (state, action) => {
            state.analyticsTickets = action.payload
        }, 
    
    }, 

   
})

export const {setCountCalibratorStore, setAnalyticsTicketsStore, } = dataSlice.actions
export default dataSlice.reducer