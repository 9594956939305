import ApiService from "./ApiService";

export async function apiGetQuestionGroup (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiGetQuestionGroupById (id) {
    return ApiService.fetchData({
        url: `/question_group/${id}`,
        method: 'get',
      
    })
}

export async function apiPostQuestionGroup (data) {
    return ApiService.fetchData({
        url: `/question_group`,
        method: 'post',
        data
    })
}

export async function apiPutQuestionGroup (id, data) {
    return ApiService.fetchData({
        url: `/question_group/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteQuestionGroup (id) {
    return ApiService.fetchData({
        url: `/question_group/${id}`,
        method: 'delete',
    })
}

export async function apiPostIncidentQuestion (id, data) {
    return ApiService.fetchData({
        url: `/question/${id}`,
        method: 'put',
        data
    })
}

export async function apiPostIncidentQuestionOption (id, data) {
    return ApiService.fetchData({
        url: `/question_option/${id}`,
        method: 'put',
        data
    })
}