import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetCameraRecords, apiGetCommentsRecord } from "services/RecordsService";
import { getSearchParam, objectToArray, orderArrayBy } from "utils/storeHube/helpers";
import { dateFormat } from "utils/storeHube/helpersDate";
import { optionHours } from "../components/CalendarColumn";


export const getRecords = createAsyncThunk('camera/getRecords', async (data) => {
    const response = await apiGetCameraRecords(data)
    return response.data
})

export const getCommentsRecord = createAsyncThunk('record/getComments', async (data) => {
    const response = await apiGetCommentsRecord(data)
    return response.data
})

export const filterRecordsByHour = (data, from, to) => {
    const newPlaylist = data.filter(r => {
        const fileAt = dateFormat(r.file_at, 'HH:mm')
        if (fileAt >= from && fileAt < to) {
            return true
        }
    })
    return orderArrayBy(newPlaylist, 'file_at')
}

export const filterScheduleByRecord = (record) => {
    let schedule = false
    const { file_at } = record
    const hour = dateFormat(file_at, 'HH:mm')
    optionHours.map(opt => {
        if (opt.id !== 4) {
            if (hour >= opt.from && hour < opt.to) {
                schedule = opt
            }
        }
    })
    return schedule
}

export const makeCamerasURL = (data) => {
    const { origin } = window.location
    const arrayQuery = objectToArray(data)
    const url = new URL(`${origin}/cameras`);
    arrayQuery.map(e => {
        const value = Object.values(e)[0]
        value && url.searchParams.append(Object.keys(e)[0], value);
    })
    return url.toString().replace(origin, '')
}

export const initialStateStore = {
    storeSelected: false
}
export const initialStateCameras = {
    storeCameras: [],
    cameraSelected: false,
    live: false
}
export const initialStateRecords = {
    list: [],
    playlist: [],
    records_per_day: false,
    mainRecord: false,
    secondsRecord: getSearchParam('seconds') || 0,
    comments: [],
    loadingComments: false
}


export const initialStateRecordControl = {
    volume: 0,
    playing: false,
    played: 0,
    speed: 1,
    muted: true
}

const params = new URLSearchParams(window.location.search);

export const initialStateQuery = {
    store: params.get('store') ?? false,
    device: params.get('device') ?? false,
    date: params.get('date') ?? false,
    record: params.get('record') ?? false,
    seconds: params.get('seconds') ?? false
}

export const initialStateFilterRecords = {
    date: params.get('date') ? new Date(params.get('date')).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10),
    hour: optionHours[0],
}

const dataSlice = createSlice({
    name: 'camerasStore/data',
    initialState: {
        store: initialStateStore,
        cameras: initialStateCameras,
        records: initialStateRecords,
        filter: initialStateFilterRecords,
        loadingFindRecords: false,
        recordControl: initialStateRecordControl,
        query: initialStateQuery
    },
    reducers: {
        clearDataCameras: (state, action) => {
            state.store = initialStateStore
            state.cameras = initialStateCameras
            state.records = initialStateRecords
            state.filter = initialStateFilterRecords
            state.query = initialStateQuery
        },
        setStoreSelected: (state, action) => {
            const { payload: store } = action
            state.store.storeSelected = store
            state.cameras.storeCameras = store?.props?.devices?.filter(device => device.device_type_id === 3)
            state.query.store = store.id
            //state.records.secondsRecord = 0
        },
        setCamerasData: (state, action) => {
            state.cameras.storeCameras = action.payload
        },
        setMainRecord: (state, action) => {
            state.records.mainRecord = action.payload
            state.recordControl.playing = true
        },
        setSecondsRecord: (state, action) => {
            state.records.secondsRecord = action.payload
        },
        setPlayList: (state, action) => {
            const { payload } = action
            const playlist = orderArrayBy(payload, 'file_at')
            state.records.playlist = playlist
            state.records.mainRecord = payload?.length > 0 ? playlist[0] : null
        },
        updatePlaylist: (state, action) => {
            const playlist = orderArrayBy(action.payload, 'file_at')
            state.records.playlist = playlist
        },
        setCameraSelected: (state, action) => {
            state.cameras.cameraSelected = action.payload
            state.query.device = action.payload.id
        },
        setAddNewComment: (state, action) => {
            state.records.comments = action.payload
        },
        setWatchLive: (state, action) => {
            state.cameras.live = action.payload
        },
        setFilterDate: (state, action) => {
            state.filter.date = action.payload
            state.query.date = action.payload
        },
        setFilterHour: (state, action) => {
            state.filter.hour = action.payload
        },
        setRecordControl: (state, action) => {
            state.recordControl = action.payload
        },
        setPageQuery: (state, action) => {
            state.query = action.payload
        }
    },
    extraReducers: {
        [getRecords.fulfilled]: (state, action) => {
            const { data, records_per_day } = action.payload
            state.records.list = data
            state.records.records_per_day = Object.entries(records_per_day).map(([key, val]) => (parseInt(key)))
            state.records.playlist = false //data
            state.records.mainRecord = state.records.playlist.length > 0 && state.records.playlist[0]
            state.loadingFindRecords = false
        },
        [getRecords.pending]: (state) => {
            state.loadingFindRecords = true
        },
        [getCommentsRecord.fulfilled]: (state, action) => {
            const { data } = action.payload
            state.records.comments = data
            state.records.loadingComments = false
        },
        [getCommentsRecord.pending]: (state) => {
            state.records.loadingComments = true
        },
        [getCommentsRecord.rejected]: (state) => {
            state.records.loadingComments = false
        },
    }
})
export const {
    setStoreSelected,
    setCamerasData,
    setCameraSelected,
    setWatchLive,
    setFilterDate,
    setFilterHour,
    setMainRecord,
    setAddNewComment,
    setSecondsRecord,
    setRecordControl,
    setPlayList,
    updatePlaylist,
    clearDataCameras,
    setPageQuery

} = dataSlice.actions

export default dataSlice.reducer