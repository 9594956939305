
import React, { useEffect } from "react";
import { Button, Notification, toast } from "components/ui";
import Pusher from "pusher-js"
import { useTranslation } from "react-i18next";
import { HiOutlineBell } from 'react-icons/hi'
import { baseUrl } from "configs/app.config";
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from "utils/deepParseJson";
import { useState } from "react";
import useAuth from "utils/hooks/useAuth";
import { useSelector } from "react-redux";



export const pusherContext = React.createContext()




const PusherProvider = ({ children }) => {

    const { authenticated } = useAuth()
    const [pusherConection, setPusherConection] = useState()


    const { signedIn } = useSelector(state => state.auth.session)


    const connectPusher = () => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)
        const accessToken = persistData.auth.session.token

        const uuid = persistData.auth.user.uuid

        //Pusher.logToConsole = true;
        const backendBaseUrl = baseUrl;
        
        const pusher = new Pusher('b7713fde0e55b6546a5c', {
            cluster: 'us2',
            authEndpoint: `${backendBaseUrl}/broadcasting/auth`,
            forceTLS: true,
            auth: {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        });

        const channel = pusher.subscribe(`private-${uuid}`);
        
        pusher.connection.bind('state_change', function (states) {
            console.log("Channels current state is " + JSON.stringify(states.current));
        });
    
        channel.bind('pusher:subscription_error', function(data) {
            console.log('subscription_error');
        });
    
        channel.bind("pusher:subscription_succeeded", function(data) {
            console.log("subscription_succeeded");
        })
        setPusherConection(channel)
    }

    useEffect(() => {
        authenticated && connectPusher()
    }, [signedIn])

    return (
        <pusherContext.Provider value={pusherConection}>
            {children}
        </pusherContext.Provider>
    )

}
export default PusherProvider




export const ConfirmNotification = (props) => {
    const { t } = useTranslation()

    const {onConfirm} = props

    const ticketProps = {
        title: props.title || t('notification'),
        icon: props.icon || <HiOutlineBell className="text-2xl text-indigo-600" />,
        textConfirm: props.textConfirm || t('confirm_capitalize'),
        textCancel:  props.textCancel || t('cancel_capitalize'),
        duration: props.duration || 0
    }


    function closeNotification(key) {
        toast.remove(key)
    }

    function openNotification() {
        const notificationKey = toast.push(
            <Notification
                title={ticketProps.title}
                duration={ticketProps.duration}
                customIcon={ticketProps.icon}
            >
                {props.children}
                <div className="text-left mt-3">
                    <Button onClick={() => (onConfirm() & closeNotification(notificationKey))} size="xs" variant="solid" className="mr-2">{ticketProps.textConfirm}</Button>
                    <Button onClick={() => closeNotification(notificationKey)} size="xs">{ticketProps.textCancel}</Button>
                </div>
            </Notification>
        )
    }
    return openNotification()
}