import React from 'react'
import { toast, Notification } from 'components/ui'
import { ConfirmDialog } from 'components/shared'
import { useSelector, useDispatch } from 'react-redux'
import { toggleDeleteConfirmation } from '../store/stateSlice'
import { apiDeleteStore } from 'services/StoreService'
import { statusResponse } from 'constants/apiResponse.constant'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const ItemDeleteConfirmation = ({ text, title, onDelete }) => {
	
	const navigate = useNavigate()

	const { t } = useTranslation()

	const dispatch = useDispatch()
	const dialogOpen = useSelector((state) => state.crudList.state.deleteConfirmation)



	const onDialogClose = () => {
		dispatch(toggleDeleteConfirmation(false))
	}

/* 	const onDelete = async () => {
		dispatch(toggleDeleteConfirmation(false))
		const {status} = await functionOnDelete(itemId)
		
		
		if ((status === statusResponse.SUCCESS)) {
			toast.push(
				<Notification title={"Successfuly Deleted"} type="success" duration={2500}>
					`{text} successfuly deleted`
				</Notification>
			)
		} 
	}
 */
	return (
		<ConfirmDialog
			isOpen={dialogOpen}
			onClose={onDialogClose}
			onRequestClose={onDialogClose}
			type="danger"
			title={`Borrar ${title}`}
			onCancel={onDialogClose}
			onConfirm={onDelete}
			confirmButtonColor="red-600"
			cancelText = {t("cancel_capitalize")}
		    confirmText=  {t("confirm_capitalize")}
		>
			<p>
				¿Está seguro de que desea eliminar {text}? <br/>
				Todos los registros relacionados con este {text} también se eliminarán.
				Esta acción no se puede deshacer.
			</p>
		</ConfirmDialog>
	)
}

export default ItemDeleteConfirmation