import ApiService from "./ApiService";

export async function apiGetTicketsCategories (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiGetTicketsCategoriesById (id) {
    return ApiService.fetchData({
        url: `tickets_categories/${id}`,
        method: 'get',
        //params
    })
}


export async function apiPostTicketCategory (data) {
    return ApiService.fetchData({
        url: `tickets_categories`,
        method: 'post',
        data
    })
}

export async function apiPutTicketCategory (id, data) {
    return ApiService.fetchData({
        url: `tickets_categories/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteTicketCategory (id) {
    return ApiService.fetchData({
        url: `tickets_categories/${id}`,
        method: 'delete',
    })
}


