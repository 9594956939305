import ApiService from "./ApiService"


export async function apiGetStoreList (data) {
    const { search } = data
    return ApiService.fetchData({
        url:  search ? search : `/stores`,
        method: 'get',
        data,
    })
}

export async function apiGetStoreListNew (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiGetStoreById (id) {
    return ApiService.fetchData({
        url: `/stores/${id}`,
        method: 'get'
    })
}

export async function apiGetStoresCrudList (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}


export async function apiPostStores (data) {
    return ApiService.fetchData({
        url: `/stores`,
        method: 'post',
        data
    })
}

export async function apiPutStore (id, data) {
    return ApiService.fetchData({
        url: `/stores/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteStore (id) {
    return ApiService.fetchData({
        url: `/stores/${id}`,
        method: 'delete',
    })
}

export async function apiStoreTimeline (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
    })
}

export async function apiChangeSubscriptionStore(id){
    return ApiService.fetchData({
        url: `/subscribe/stores/${id}`,
        method: 'post'
    })
}

export async function apiSyncRecordsStore(id){
    return ApiService.fetchData({
        url: `/stores/${id}/sync_records`,
        method: 'post'
    })
}



/* OLDS */

export async function apiGetScrumBoards (id) {
    return ApiService.fetchData({
        url:  `/stores/${id}`,
        method: 'get',
    })
}

export async function apiGetProjectDashboardData (params) {
    return ApiService.fetchData({
        url: '/project/dashboard',
        method: 'get',
        params
    })
}


export async function apiPutProjectList (data) {
    return ApiService.fetchData({
        url: '/project/list/add',
        method: 'put',
        data
    })
}

/* export async function apiGetScrumBoards () {
    return ApiService.fetchData({
        url: '/project/scrum-board/boards',
        method: 'post',
    })
} */

export async function apiGetScrumBoardtMembers () {
    return ApiService.fetchData({
        url: '/project/scrum-board/members',
        method: 'post',
    })
}

export async function apiGetScrumBoardtTicketDetail (params) {
    return ApiService.fetchData({
        url: '/project/scrum-board/tickets/detail',
        method: 'get',
        params
    })
}
