import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetKnowledgeCategory } from 'services/KnowledgeCategoryService';
import { apiGetKnowledgeArticle } from 'services/KnowledgeService';

export const getKnowledgeCategory = createAsyncThunk("stores/data/getKnowledgeCategory", async (data) => {
    const response = await apiGetKnowledgeCategory(data)
    return response.data
    
})

export const getKnowledgeArticles = createAsyncThunk('knowledgeBaseHelpCenter/data/getKnowledgeArticles',async (data) => {
    const response = await apiGetKnowledgeArticle(data)
    return response.data
})

const dataSlice = createSlice({
    name: 'knowledgeBaseHelpCenter/data',
    initialState: {
        loading: false,
        isSearchResult: false,
        searchCategory: '',
        queryText: '',
        categories: [],
        articles: [], 
        searchArticle: [], 
        searchWord: ""
    },
    reducers: {
        setSearchCategory: (state, action) => {
            state.searchCategory = action.payload
        },
        setQueryText: (state, action) => {
            state.queryText = action.payload
        },
        setSearch: (state, action) => {
            state.isSearchResult = action.payload
        },
        setSearchArticle: (state, action) => {
            state.searchArticle = action.payload
        },
        setSearchWord: (state, action) => {
            state.searchWord = action.payload
        },
    },
    extraReducers: {
        [getKnowledgeCategory.fulfilled]: (state, action) => {
            state.loading = false
            state.categories = action.payload.data
        },
        [getKnowledgeCategory.pending]: (state) => {
            state.loading = true
        },
        [getKnowledgeArticles.fulfilled]: (state, action) => {
            state.loading = false
            state.articles = action.payload.data
        },
        [getKnowledgeArticles.pending]: (state) => {
            state.loading = true
        },
    }
})

export const { setSearchCategory, setQueryText, setSearch, setSearchArticle, setSearchWord } = dataSlice.actions

export default dataSlice.reducer
