import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    loading: true,
    categories: [],
    parentCategories: [],
    status: []
}


const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setTicketsData: (state, action) => {

            const { ticket_category, ticket_status } = action.payload

            state.categories = ticket_category
            state.parentCategories = ticket_category.filter(category => category.parent_id === null) 
            state.status = ticket_status

            state.loading = false
        },
        setLoadingTicketData: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setTicketsData, setLoadingTicketData } = dataSlice.actions

export default dataSlice.reducer 