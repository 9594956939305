import { Dialog } from "components/ui"
import React, { Suspense } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toggleChangeOperator } from "../store/stateSlice";
import ChangeOperatorContent from "./ChangeOperatorContent";


const ChangeOperatorDialog = () => {

    const dispatch = useDispatch()

    const { changeOperatorView } = useSelector(state => state.ticketList.state)

    const onDialogClose = () => {
        dispatch(toggleChangeOperator(false))
    }

    return (
        <Dialog
            isOpen={changeOperatorView}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            width={500}
        >

            <Suspense fallback={<></>}>
                <ChangeOperatorContent/>
            </Suspense>
        </Dialog>
    )
}

export default ChangeOperatorDialog