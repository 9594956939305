import { Dialog } from "components/ui";
import { STORE_HUB } from "components/ui/utils/constant";
import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { toggleTicketDialog } from "../store/stateSlice";
import AddTicket from "./AddTicket";
import TicketContent from "../components/TicketContent";
import { resetValuesForTicket } from "../store/globalSlice";
import { deleteKey } from "utils/storeHube/localStorage";


const TicketDialog = () => {


    const dispatch = useDispatch()
    const viewTicket = useSelector(state => state.ticketList.state.viewTicket)
    const selectedTicket = useSelector(state => state.ticketList.data.selectedTicket)
    const dialogView = useSelector(state => state.ticketList.state.dialogView)
    const QUESTION_PRIMARY_KEY = `question_group_primary`

    const onDialogClose = () => {
        dispatch(toggleTicketDialog(false))
        dispatch(resetValuesForTicket())
        deleteKey(QUESTION_PRIMARY_KEY)
        //dispatch(setAddTicketFromCameras(false))
        //dispatch(setSecondsForTicket(0))
        //dispatch(setDialogView(''))
        //dispatch(setSelectedTicket(false))
    }

    return (
        <Dialog
            isOpen={viewTicket}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            width={700}
            height={600}
        >
            <Suspense fallback={<></>}>
                {dialogView == STORE_HUB.TICKET && <div className='mt-6'><TicketContent data={selectedTicket} /></div>}
                {dialogView == STORE_HUB.ADD_TICKET && <AddTicket />}
            </Suspense>
        </Dialog>
    )
}

export default TicketDialog