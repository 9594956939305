export const SIZES = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg', 
    XL: "xl"
}

export const CONTROL_SIZES = {
    [SIZES.XS]: 7,
    [SIZES.SM]: 9,
    [SIZES.MD]: 11,
    [SIZES.LG]: 14, 
    [SIZES.XL]: 16
}

export const LAYOUT = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
    INLINE: 'inline'
}

export const DIRECTIONS = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left'
}

export const SELECTION_MODES = {
	YEAR: 'year',
	MONTH: 'month',
	DAY: 'day'
}

export const PICKER_VIEWS = {
	YEAR: 'year',
	MONTH: 'month',
	DATE: 'date',
}

export const STATUS = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning'
}

export const STEPS_STATUS = {
    COMPLETE: 'complete',
    PENDING: 'pending',
    IN_PROGRESS: 'in-progress',
    ERROR: 'error'
}

export const PLACEMENT = {
    TOP_START: 'top-start',
    TOP_CENTER: 'top-center',
    TOP_END: 'top-end',
    BOTTOM_START: 'bottom-start',
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_END: 'bottom-end',
    MIDDLE_START_TOP: 'middle-start-top',
    MIDDLE_START_BOTTOM: 'middle-start-bottom',
    MIDDLE_END_TOP: 'middle-end-top',
    MIDDLE_END_BOTTOM: 'middle-end-bottom'
}

export const DROPDOWN_ITEM_TYPE = {
    DEFAULT: 'default',
    HEADER: 'header',
    DIVIDER: 'divider',
    CUSTOM: 'custom',
}

export const STORE_HUB = {
    TICKET: 'TICKET',
    ADD_TICKET: 'ADD_TICKET', 

    VIEW_ADD_OR_EDIT_USER: "VIEW_ADD_OR_EDIT_USER", 
    DELETE_USER: "DELETE_USER", 

    VIEW_ADD_OR_EDIT_TEAMS: "VIEW_ADD_OR_EDIT_TEAMS", 
    DELETE_TEAM: "DELETE_TEAM",

    VIEW_ADD_OR_EDIT_CHANNELS: "VIEW_ADD_OR_EDIT_CHANNELS", 
    DELETE_CHANNEL: "DELETE_CHANNEL",

    VIEW_ADD_OR_EDIT_ISSUES_TYPES: "VIEW_ADD_OR_EDIT_ISSUES_TYPE", 
    DELETE_ISSUES_TYPE : "DELETE_ISSUES_TYPE", 

    VIEW_ADD_OR_EDIT_STORE: "VIEW_ADD_OR_EDIT_STORE", 
    DELETE_STORE: "DELETE_STORE", 

    VIEW_ADD_OR_EDIT_DEVICES: "VIEW_ADD_OR_EDIT_DEVICES",
    DELETE_DEVICE: "DELETE_DEVICE", 

    VIEW_ADD_OR_EDIT_PARTNERS: "VIEW_ADD_OR_EDIT_PARTNERS",
    DELETE_PARTNER : "DELETE_PARTNER", 

    VIEW_ADD_OR_EDIT_ROLES: "VIEW_ADD_OR_EDIT_ROLES", 
    DELETE_ROL : "DELETE_ROL", 

    VIEW_ADD_OR_EDIT_QUESTION_GROUP: "VIEW_ADD_OR_EDIT_QUESTION_GROUP", 
    DELETE_QUESTION_GROUP : "DELETE_QUESTION_GROUP", 

    VIEW_ADD_OR_EDIT_PAGES: "VIEW_ADD_OR_EDIT_PAGES", 
    DELETE_PAGE : "DELETE_QUESTION_PAGE", 

    VIEW_ADD_OR_EDIT_LOCATIONS: "VIEW_ADD_OR_EDIT_LOCATIONS", 
    DELETE_LOCATION : "DELETE_QUESTION_LOCATION", 

    VIEW_ADD_OR_EDIT_EVENTS: "VIEW_ADD_OR_EDIT_EVENTS", 
    DELETE_EVENTS : "DELETE_EVENTS", 

    VIEW_ADD_OR_EDIT_TAGS: "VIEW_ADD_OR_EDIT_TAGS", 
    DELETE_TAGS : "DELETE_TAGS", 

    VIEW_ADD_OR_EDIT_KNOWLEDGE_CATEGORIES: "VIEW_ADD_OR_EDIT_KNOWLEDGE_CATEGORIES", 
    DELETE_KNOWLEDGE_CATEGORIES : "DELETE_KNOWLEDGE_CATEGORIES", 

    VIEW_ADD_OR_EDIT_KNOWLEDGE_ARTICLE: "VIEW_ADD_OR_EDIT_KNOWLEDGE_ARTICLE", 
    DELETE_KNOWLEDGE_ARTICLE : "DELETE_KNOWLEDGE_ARTICLE"

    
}

export const DAY_DURATION = 86400000;