import { Button, Card, Tooltip } from 'components/ui'
import FileItemCard from 'components/viewComponents/FileItemCard'
import React from 'react'
import { HiOutlineDownload, HiOutlinePaperClip } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { TicketSection } from './TicketContent'

const TicketAttachment = ({ ticket_attachment }) => {

    const { t } = useTranslation()
    
    return (
        <TicketSection title={t("attachments" )}icon={<HiOutlinePaperClip />}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                {
                    ticket_attachment.map(attachment => (
                        <Card bodyClass="px-2 pt-2 pb-1" key={attachment.id}>
                            <FileItemCard file={attachment} />
                            <div className="mt-1 flex justify-between items-center">
                                <span className="text-xs">{(attachment.size/1024).toFixed(2)} KB</span>
                                <div className="flex items-center">
                                    <Tooltip title={t('download')}>
                                        <a href={attachment.file} download target="_blank">
                                            <Button className="mr-1 rtl:ml-1" variant="plain" size="xs" icon={<HiOutlineDownload />} />
                                        </a>
                                    </Tooltip>
                                    {/* <Tooltip title="Delete">
													<Button variant="plain" size="xs" icon={<HiOutlineTrash />} />
												</Tooltip> */}
                                </div>
                            </div>
                        </Card>
                    ))
                }
            </div>
        </TicketSection>
    )
}

export default TicketAttachment