import { ConfirmDialog } from "components/shared";
import { Notification, toast } from "components/ui";
import { statusResponse } from "constants/apiResponse.constant";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { apiPutTicket } from "services/TicketsService";
import { deleteDuplicateData, showMessageError } from "utils/storeHube/helpers";
import { setSelectedTicket, updateTicketList } from "../store/dataSlice";
import { toggleChangeStatusConfirmation } from "../store/stateSlice";


const TicketChangeStatusConfitmation = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()


    const dialogOpen = useSelector((state) => state.ticketList.state.changeStatusConfirmation)
    const { selectedTicketId } = useSelector((state) => state.ticketList.data)
    const { ticketList } = useSelector((state) => state.ticketList.data)

    const onDialogClose = () => {
        dispatch(toggleChangeStatusConfirmation(false))
    }

    const onChangeStatus = async () => {
        dispatch(toggleChangeStatusConfirmation(false))

        try {
            const { status, data } = await apiPutTicket(selectedTicketId, { ticket_status_id: 2 })
            if (status === statusResponse.SUCCESS) {

                const newTicketList = deleteDuplicateData(ticketList, data.data)

                dispatch(updateTicketList(newTicketList))
                dispatch(setSelectedTicket(data.data))

                toast.push(
                    <Notification title={t('updated')} type="success" duration={2500}>
                        {t('ticket_successUpdate')}
                    </Notification>
                )
            }

        } catch (error) {
            showMessageError(error.response.data.message)
        }


    }

    return (
        <ConfirmDialog
            isOpen={dialogOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            type="info"
            title={t('change_status')}
            onCancel={onDialogClose}
            onConfirm={onChangeStatus}
            confirmButtonColor="blue-600"
            cancelText={t('cancel_capitalize')}
            confirmText={t('confirm_capitalize')}
        >
            <p>
                {t('change_status_label')}
            </p>
        </ConfirmDialog>
    )
}

export default TicketChangeStatusConfitmation