import { combineReducers } from '@reduxjs/toolkit'
import state from './stateSlice'
import data from './dataSlice'
import global from './globalSlice'

const reducer = combineReducers({
    state,
    data,
    global
})

export default reducer