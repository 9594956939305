import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetParameterOpenHours } from "services/ParametersService";
import dayjs from "dayjs";

export const getParameterOpenHours = createAsyncThunk('parameters/getOpenHours', async () =>{
    const response = await apiGetParameterOpenHours()
    return response.data
})

export const days = [
    { name: "monday", key: "monday" },
    { name: "tuesday", key: "tuesday" },
    { name: "wednesday", key: "wednesday" },
    { name: "thursday", key: "thursday" },
    { name: "friday", key: "friday" },
];

export const weekend = [
    { name: "saturday", key: "saturday" },
    { name: "sunday", key: "sunday" },
];



const hourToDate = (date) => {
    const d = dayjs().format(`YYYY-MM-DD ${date}:00`)
    return dayjs(d).toDate()
}

export const makeHours = (data) => {
    let wk = {}
    Object.keys(data).map((day) => {
        if (days.map(e => e.name).includes(day)) {
            const hours = data[day]
            if (hours[0]) {
                wk.first_opening_time = hourToDate(hours[0].split('-')[0])
                wk.first_closing_time = hourToDate(hours[0].split('-')[1])
            }
            if (hours[1]) {
                wk.second_opening_time =  hourToDate(hours[1].split('-')[0])
                wk.second_closing_time =  hourToDate(hours[1].split('-')[1])
            }
        }
        if (weekend.map(e => e.name).includes(day)) {
            const hours = data[day]
            if (hours[0]) {
                wk.first_weekend_opening = hourToDate(hours[0].split('-')[0])
                wk.first_weekend_closing = hourToDate(hours[0].split('-')[1])
            }
            if (hours[1]) {
                wk.second_weekend_opening = hourToDate(hours[1].split('-')[0])
                wk.second_weekend_closing = hourToDate(hours[1].split('-')[1])
            }
        }
    });

    return wk
}


export const initialState = {
    data: false,
    loading: true
}

const dataSlice = createSlice({
    name: 'parameters/openhours',
    initialState,

    reducers: {
        setOpenHours: (state, action) => {
            console.log(action.payload);
            state.data = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: {
        [getParameterOpenHours.pending]: (state) => {
            state.data = false
            state.loading = true
        },
        [getParameterOpenHours.fulfilled]: (state, action) => {
            state.data = action.payload.data?.openhours
        },
    }
})

export const {setOpenHours, setLoading} = dataSlice.actions
export default dataSlice.reducer