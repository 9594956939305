import React, { useEffect, useRef, useState } from "react";

import { TicketSection } from "./TicketContent";
import { HiOutlineExclamation, HiXCircle, HiOutlineXCircle, HiOutlineTrash } from "react-icons/hi";
import { useTranslation } from "react-i18next";

import useThemeClass from "utils/hooks/useThemeClass";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, Button, Notification, toast } from "components/ui";
import { inventoryStatusColor } from "utils/storeHube/statusColor";
import { findTicketCategory, findUser } from "utils/storeHube/helpers";
import { Loading } from "components/shared";
import { apiLinkTicket } from "services/TicketsService";
import { useDispatch } from "react-redux";
import { setSelectedTicket } from "../store/dataSlice";


const TicketIncidents = ({ incidents, incidence, ticket: selectedTicket }) => {
  
  const { t } = useTranslation()
  const { textTheme } = useThemeClass()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { ticketSubCategories, allUsers, loadingGlobalTicket, loadingUsers } = useSelector(state => state.ticketList.global)

  const closeNotification = (key) => {
    toast.remove(key)
  }

  const unlink = async (id) => {
    try {
      await apiLinkTicket(id, { parent_id: null })
      const ticket = structuredClone(selectedTicket)
      ticket.props.incidents = incidents.filter(i => i.id !== id)
      dispatch(setSelectedTicket(ticket))
    } catch (error) { }
  }

  const openDeleteConfirmation = async (id) => {
    const notificationKey = await toast.push(
      <Notification title={t('linked_delete')} type={'danger'} duration={0}>
        <div>
          {t('linked_delete_message')}
        </div>
        <div className="flex text-right mt-3">
          <Button
            onClick={() => (unlink(id) & closeNotification(notificationKey))}
            size="xs"
            variant="solid"
            className="mr-2"
            color="red-500"
          >
            {t('confirm_capitalize')}
          </Button>
          <Button
            onClick={() => closeNotification(notificationKey)}
            size="xs"
          >
            {t('close')}
          </Button>
        </div>
      </Notification>
    )
  }

  return (
    <TicketSection title={incidence ? t('incidents') : t("tickets")} icon={<HiOutlineExclamation />}>
      {
        loadingUsers && loadingGlobalTicket ? <Loading loading={loadingGlobalTicket} /> :
          <>
            <p className="mb-4">{incidence ? t("automatically_created_incidents") : null}</p>
            {incidents.map((ticket) => (
              <div key={ticket.id} className="my-2 flex flex-wrap items-center gap-2">
                <Link to={`/tickets/inbox?ticket=${ticket.id}`} className={`underline cursor-pointer select-none font-semibold text-gray-900 dark:text-gray-100`}>
                  {`#${ticket.id}`}
                </Link>
                <p className="font-semibold">{findTicketCategory(ticketSubCategories, ticket?.ticket_category_id)}</p>
                <p>{t("assigned_to")} : {findUser(allUsers, ticket.operator_id)} </p>
                <div className="flex items-center">
                  <Badge innerClass={`${inventoryStatusColor[ticket.ticket_status_id].dotClass}`} />
                  <span className=" text-xs ml-2 rtl:mr-2">{inventoryStatusColor[ticket.ticket_status_id].label}</span>
                </div>
                <Button
                  variant="plain"
                  size="xs"
                  onClick={() => openDeleteConfirmation(ticket.id)}
                  icon={<HiOutlineTrash />}
                >
                </Button>
              </div>
            )
            )}
          </>
      }

    </TicketSection>
  )
}

export default TicketIncidents