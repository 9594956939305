const { createSlice } = require("@reduxjs/toolkit");


const initialState = {
    storeColumnExpand: true
}

const stateSlice = createSlice({
    name: 'camerasStore/state',
    initialState,
    reducers: {
        setStoreColumnExpand: (state, action) => {
            state.storeColumnExpand = action.payload
        }
    }
})

export const { 
    setStoreColumnExpand
} = stateSlice.actions

export default stateSlice.reducer