import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from "@sentry/react";

Sentry.init({
   dsn: process.env.REACT_APP_SENTRY_FRONT_DSN,
   integrations: [
      new Sentry.BrowserTracing({
         tracePropagationTargets: [process.env.REACT_APP_SENTRY_TARGET_ARG, process.env.REACT_APP_SENTRY_TARGET_PE],
      }),
      new Sentry.Replay(),
   ],
   // Performance Monitoring
   tracesSampleRate: 1.0, // Capture 100% of the transactions
   // Session Replay
   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(

   <App />

   /*   <React.StrictMode>
      <App />
    </React.StrictMode>  */
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
