import axios from "axios";
import ApiService from "./ApiService";

export async function apiGetCameraRecords(params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
    })
}

export async function apiGetCommentsRecord(id) {
    return ApiService.fetchData(({
        url: `/records_comments/${id}`,
        method: 'get'
    }))
}

export async function apiAddCommentRecord(id, data) {
    return ApiService.fetchData(({
        url: `/records_comments/${id}`,
        method: 'post',
        data
    }))
}

export async function apiRequestBluredVideo(data) {
    return ApiService.fetchData(({
        url: `/records/blur/request`,
        method: 'post',
        data
    }))

}