
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.state = { errorInfo: false }
  }


  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    this.setState({ errorInfo: error.toString() + errorInfo.componentStack });
  }

  render() {
    if (this.state.hasError) {

      return (
        <div className="flex flex-col justify-center items-center h-[100vh] w-full bg-white p-5">
          <img className="max-w-[200px]" src="/img/others/img-2.png" />
          <div className="mt-6 text-center">
            <h3 className="mb-2">Ups! Algo salio mal.</h3>
            <p>Por favor copia esta pantalla y enviarla al equipo de soporte de StoreHub. Gracias!</p>
            <button className="button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 m-4">
              <a href="/home">Volver a Inicio</a>
            </button>
            <p className='text-red-500	 p-4'>{this.state.errorInfo}</p>
          </div>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;