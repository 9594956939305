import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import duration from 'dayjs/plugin/duration'
import isToday from "dayjs/plugin/isToday";
import tz from "dayjs/plugin/timezone"
import moment from "moment";
import 'moment/locale/es';
moment.locale('es');


dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(tz)



/* export const formatterDate = (d) => {

    console.log(d);

    const date = typeof (d) === 'string' ? d?.replace('.000000Z', '') : d
    let createdDate = moment(date).format('DD MMMM')
    const created = moment(date).format('YYYY-MM-DDTHH:mm:ss')
    const now = moment()

    var isToday = now.isSame(created, "day");
    if (isToday) {
        if (now.diff(created, 'hour') < 1) {
            createdDate = now.to(created)
        } else {
            createdDate = moment(date).format('HH:mm');
        }
    } else {
        createdDate = moment(date).format('DD MMMM HH:mm');
    }
    return createdDate
} */

export const newDate = (date = false) => {
    return date ? moment(date).toDate() : moment().toDate()
}

export const dateFormat = (date = false, format = 'DD-MM-YYYY HH:mm') => {
    if (date === null) return
    if (date) {
        return moment(date).format(format)
    }
    return moment().format(format)
}

export const timeDiff = (d, unit = 'minute') => {
    if (d) {
        const now = moment()
        const date = moment(d)
        return now.diff(date, unit)
    }
    return 0
}


export const secondsToMinutes = (date) => {
    const durationObj = dayjs.duration(date, 'second');
    const minutes = durationObj.minutes().toString().padStart(2, '0');;
    const seconds = durationObj.seconds().toString().padStart(2, '0');
    return `${minutes}:${seconds}`
}

export const formatDateInput = (date = null) => {
    const d = date ? date : new Date()
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const substractMinutes = (h, minutes) => {
    const hour = moment(h, 'DD-MM-YYYY HH:mm').subtract(minutes, 'minutes').format('DD-MM-YYYY HH:mm');
    return hour;
}