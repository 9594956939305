import React, { useRef, useState } from 'react'
import { Input, Spinner, Switcher, Tooltip, Badge } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { HiOutlineTrash, HiOutlineExclamation } from "react-icons/hi";
import { handleNameChange, onCheckRequired } from './helpers'
import { FiSave } from 'react-icons/fi'

const QuestionTypeTextArea = ({ handleAddNewQuestion, questionTypeId, question, edit, handleRemoveQuestion, isAnswer, questionForAnswer, onChange, errorMessage, defaultValue }) => {

  const { t } = useTranslation()

  const [newOptionEdit, setNewOptionEdit] = useState(false)
  const [nameQuestion, setNameQuestion] = useState(question ? question.name : "")
  const [uniqueUuid, setUniqueUuid] = useState(question ? (question.uuid || question.uuid2) : "")
  const [optionListModified, setOptionListModified] = useState(question ? question.question_option : [])
  const [questionType_id, setQuestionTypeId] = useState(question ? question.question_type_id : questionTypeId)
  const [questionRequired, setQuestionRequired] = useState(question ? question.required : false)
  const [loading, setLoading] = useState(false)
  const [incidentsQuestion, setIncidentsQuestions] = useState(question ? question.incidents : "");
  const [questionTag, setQuestionTag] = useState(question && question.tags ? question.tags[0] : null)
  const [penalityQuestion, setPenalityQuestion]=useState (false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (newOptionEdit) {
      inputRef.current.focus()
    }
  }, [newOptionEdit])


  const updateQuestions = () => {
    setLoading(true);
    handleAddNewQuestion(nameQuestion, optionListModified, questionType_id, questionRequired, uniqueUuid, incidentsQuestion, penalityQuestion);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    !isAnswer && updateQuestions();
  }, [optionListModified, nameQuestion, questionRequired, incidentsQuestion, penalityQuestion]);

  const handleIncidents = (event) => {
    if (event === false) setPenalityQuestion(false)
    setIncidentsQuestions(event)
  }
  const handlePenality = (event) => {
    setPenalityQuestion(event)
  }

  return (
    <>
      {
        isAnswer ?
          <>
            <p className='mb-2'>
              {questionForAnswer && questionForAnswer.name}
              {questionForAnswer.required && <span className="text-red-500">*</span>}
            </p>
            <div className="flex-col w-5/5">
              <Input
                textArea
                onChange={ev => onChange(ev.target.value)}
                defaultValue={defaultValue}
              />
            </div>
            <span className='text-red-500'> {errorMessage} </span>
          </>
          :
          <div>
            <Input onChange={(event) => handleNameChange(setNameQuestion, event)} placeholder={t("question")} value={nameQuestion} />
            {edit && questionTag &&
              <div className=" flex items-center mr-2 rtl:ml-2 mt-3 gap-2">
                <p className=' capitalize '>{t("tag")} : </p>
                <div className="flex items-center gap-2 border rounded-lg px-1">
                  <Badge style={{ "backgroundColor": `${questionTag.color}` }} />
                  <span className={`capitalize font-semibold `}>
                    {questionTag.name}
                  </span>
                </div>
              </div>
            }
            <div className="flex justify-between w-full items-center mb-5 mt-5">
            <div className="flex w-3/6">
            <div className="flex  gap-2  w-full">
                  <Input disabled placeholder={t("only_for_preview")} textArea />
                </div>
                <div className="ml-5 mt-3 ">
                  <Tooltip title={t("message_incidence")}>
                    {incidentsQuestion && (
                      <HiOutlineExclamation className=" text-lg " />
                    )}
                  </Tooltip>
                </div>
              </div>
              <div className='flex flex-wrap gap-3 items-end '>
                <div className='flex items-center mr-2 '>
                  <span className='mr-2'> {t("check_incidence")}</span>
                  <Switcher defaultChecked={question && question.incidents} onChange={(event) => handleIncidents(event)} />
                </div>
                {
                  incidentsQuestion &&
                  <div className='flex items-center mr-2 '>
                    <span className='mr-2'> {t("check_penality")}</span>
                    <Switcher defaultChecked={question && question.penality} onChange={(event) => handlePenality(event)} />
                  </div>
                }
              </div>
            </div>
            <div className='flex items-center mb-3 justify-end'>
              <div className='flex items-center mr-2'>
                <span className='mr-2'> {t("isRequired")}</span>
                <Switcher defaultChecked={question && question.required} onChange={(event) => onCheckRequired(setQuestionRequired, event)} />
              </div>
              {
                edit &&
                <>
                  <div className='mr-5 ' onClick={() => handleRemoveQuestion(question)}>
                    <Tooltip title="Borrar pregunta">
                      <HiOutlineTrash className=' text-lg ' />
                    </Tooltip>
                  </div>
                  <div className='mr-5' onClick={() => updateQuestions()}>
                    <Tooltip title="Guardar cambios">
                      {loading ? <Spinner /> : <FiSave className=' text-lg ' />}
                    </Tooltip>
                  </div>
                </>
              }
            </div>

          </div>
      }
    </>
  )
}
export default QuestionTypeTextArea