import React from "react";
import { Skeleton } from "components/ui";
import { randomInteger } from "utils/randomNumber";



const SkeletonTicketComments = () => {

    return (
        <>
            {Array(randomInteger(2, 4)).fill(1).map((el, key ) =>
                <div key={key} className="flex flex-auto my-8 gap-2">
                    <div className=''>
                        <Skeleton variant="circle" height={35} width={35} />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <Skeleton height={10} width="50%" />
                        <Skeleton height={10} width={`${randomInteger(70, 100)}%`} />
                        <Skeleton height={10} width={`${randomInteger(10, 50)}%`} />
                    </div>
                </div>
            )}
        </>
    )

}

export default SkeletonTicketComments