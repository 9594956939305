
export const baseUrl = process.env.REACT_APP_API_HOST
const appConfig = {
    //apiPrefix: '/api',
    apiPrefix: `${baseUrl}/api/backoffice`,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '',
    enableMock: false
}

export default appConfig