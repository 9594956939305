import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
name: "crudList/state", 
initialState:{
    deleteConfirmation:false, 
    deleteItemId:"", 
    dialogDeleteView:  "", 
    viewCrudData: false,
    viewCrudTag: false,  
    dialogCrudTagView: "",
    viewCrudKnowledgeCategory: false, 
    dialogCrudKnowledgeCategoryView: "", 
    dialogCrudView: "",
    dialogCrudAutomaticIncidentView: "",
    inboxSideBarExpand: true,
    inboxMobileSideBarExpand: false,
    searchValue: ""
}, 
reducers:{
    toggleCrudDataDialog: (state, action)=>{
        state.viewCrudData = action.payload
    },
    toggleCrudTagDialog: (state, action)=>{
        state.viewCrudTag = action.payload
    },
    setDialogCrudView: (state, action) =>{
        state.dialogCrudView = action.payload
    }, 
    setDialogCrudTagView: (state, action) =>{
        state.dialogCrudTagView = action.payload
    },
    setViewCrudKnowledgeCategory: (state, action) =>{
        state.viewCrudKnowledgeCategory = action.payload
    },
    setDialogCrudKnowledgeCategoryView: (state, action) =>{
        state.dialogCrudKnowledgeCategoryView = action.payload
    },
    setDialogCrudAutomaticIncidentView: (state, action) =>{
        state.dialogCrudAutomaticIncidentView = action.payload
    },
    toggleDeleteConfirmation: (state, action) => {
        state.deleteConfirmation = action.payload
    },
    setSelectedDeleteItemId : (state, action) =>{
        state.deleteItemId = action.payload
    },
    setDialogDeleteView : (state, action) => {
        state.dialogDeleteView = action.payload
    }, 
    setInboxSideBarExpand: (state, action) => {
        state.inboxSideBarExpand =  action.payload
    },
    setInboxMobileSideBarExpand: (state, action) => {
        state.inboxMobileSideBarExpand =  action.payload
    }, 
    setSearchValue:(state, action) =>{
        state.searchValue=action.payload
    }

}
})

export const {toggleCrudDataDialog, setDialogCrudView, toggleDeleteConfirmation, setSelectedDeleteItemId, setDialogDeleteView,setInboxSideBarExpand, setInboxMobileSideBarExpand, toggleCrudTagDialog,setDialogCrudTagView, setDialogCrudAutomaticIncidentView, setDialogCrudKnowledgeCategoryView, setViewCrudKnowledgeCategory, setSearchValue} = stateSlice.actions
export default stateSlice.reducer