import React, { useState } from "react";
import { Button, Calendar, DatePicker, Skeleton } from "components/ui";
import { HiArrowSmLeft, HiArrowSmRight, HiReply } from 'react-icons/hi'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeCamerasURL, setFilterDate } from "../store/dataSlice";
import { setStoreColumnExpand } from "../store/stateSlice";
import useResponsive from "utils/hooks/useResponsive";
import { t } from "i18next";
import { formatDateInput, newDate } from "utils/storeHube/helpersDate";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { difference } from "lodash";
import StoreCameras from "./StoreCameras";
import { Loading } from "components/shared";
import SelectHour from "./SelectHour";



export const optionHours = [
    { id: 1, key: 'morning', label: 'Mañana', from: "07:00", to: "13:59" },
    { id: 2, key: 'afternoon', label: 'Tarde', from: "14:00", to: "19:59" },
    { id: 3, key: 'night', label: 'Noche', from: "20:00", to: "23:59" },
    { id: 4, key: 'all', label: 'Todos', from: "00:00", to: "23:59" },
]


const CalendarColumn = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { smaller } = useResponsive()

    const { storeColumnExpand } = useSelector(store => store.camerasStoreData.state)
    const { loadingGlobalTicket } = useSelector(store => store.ticketList.global)
    const { records, query, filter } = useSelector(store => store.camerasStoreData.data)
    const { records_per_day } = records

    const [unavailableDays, setUnavailableDays] = useState()

    useEffect(() => {
        let d = []
        for (let i = 0; i <= 31; i++) {
            d.push(i)
        }
        const days = difference(d, records_per_day);
        setUnavailableDays(days)

    }, [records_per_day])


    const [value, setValue] = useState()

    const handleChangeDate = async (d) => {
        setValue(d)
        const date = formatDateInput(d).slice(0, 10)
        dispatch(setFilterDate(date))
        navigate(makeCamerasURL({ ...query, date: date, record: false, seconds: false }))
    }


    return (

        <div >
            <Loading
                type={'cover'}
                loading={loadingGlobalTicket}
            >
                {smaller.xl ?
                    <div className="absolute p-4 grid gap-y-4">
                        <Button
                            /*  icon={storeColumnExpand ? <HiArrowSmLeft /> : <HiArrowSmRight />} */
                            onClick={() => dispatch(setStoreColumnExpand(!storeColumnExpand))}
                            size="xs"
                            icon={<HiReply />}
                            className="my-2 w-20"
                        /*  variant="plain"
                        shape="circle" */
                        >
                            <span>{t('menu')}</span>
                        </Button>
                        {loadingGlobalTicket
                            ? <Skeleton className="rounded-lg" />
                            : <DatePicker
                                locale="es"
                                placeholder={t('select_date')}
                                className="w-ful"
                                value={value}
                                onChange={(date) => handleChangeDate(date)}
                            />
                        }
                        <SelectHour />
                    </div>

                    : <div className="h-full p-2 hidden lg:block lg:border-r border-gray-200 w-[300px]  dark:border-gray-600">
                        <div>
                            <Button
                                icon={storeColumnExpand ? <HiArrowSmLeft /> : <HiArrowSmRight />}
                                onClick={() => dispatch(setStoreColumnExpand(!storeColumnExpand))}
                                size="sm"
                                variant="plain"
                                shape="circle"
                            />
                            <div>
                                <Calendar
                                    locale="es"
                                    defaultMonth={newDate(query.date)}
                                    value={value}
                                    onChange={(date) => handleChangeDate(date)}
                                />
                                <hr className="my-6" />
                            </div>
                            <div className="mb-6">
                                <h6 className="mb-3">{t('schedule')}</h6>
                                <SelectHour />
                            </div>
                            <div>
                                <StoreCameras />
                            </div>
                        </div>
                    </div>
                }
            </Loading>
        </div>
    )
}

export default CalendarColumn