import React, { useState, useRef } from 'react'
import { Input, Avatar, ScrollBar, Button, Notification, toast } from 'components/ui'
import wildCardSearch from 'utils/wildCardSearch'

import { HiOutlineSearch } from 'react-icons/hi'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import AvatarProfile from 'components/ui/Avatar/AvatarProfile'
import { useTranslation } from 'react-i18next'
import { apiPutTicket } from 'services/TicketsService'
import { deleteDuplicateDataToFinal, searchInArray, showMessageError } from 'utils/storeHube/helpers'
import { setSelectedTicket, updateTicketList } from '../store/dataSlice'
import { statusResponse } from 'constants/apiResponse.constant'
import { toggleChangeOperator } from '../store/stateSlice'
import { Loading } from 'components/shared'

const ChangeOperatorContent = () => {

	const inputRef = useRef()

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { selectedTicket, selectedTicketId, usersAllowed, loadingUsersAllowed, ticketList } = useSelector(state => state.ticketList.data)
	
    const userLogin = useSelector(state => state.auth.user)

	const [operatorList, setOperatorList] = useState(usersAllowed)
	const [loading, setLoading] = useState(false)
	const [beingOperator, setBeingOperator] = useState(false)

	const debounceFn = debounce(handleDebounceFn, 500)

	function handleDebounceFn(query) {
		const list = searchInArray(query, usersAllowed)
		setOperatorList(list)
	}

	const onSearch = (e) => {
		debounceFn(e.target.value.toLowerCase())
	}

	const isOperator = (id) => {
		const { operator } = selectedTicket.props
		if (operator) {
			return operator.id === id
		}
	}

	const onChangeOperator = async (operator) => {
		setBeingOperator(operator)
		setLoading(true)
		try {
			const { data, status } = await apiPutTicket(selectedTicketId, { operator_id: operator })
			if (status === statusResponse.SUCCESS) {
				const newTicketList =  deleteDuplicateDataToFinal(ticketList, data.data)
				 if(data.data.props.operator.id !== userLogin.id){
					dispatch(updateTicketList(newTicketList)) 
				 } 
				 dispatch(setSelectedTicket(data.data))
				toast.push(
					<Notification title={t('updated')} type="success" duration={2500}>
						{t('ticket_successAssigned')}
					</Notification>
				)
			}

		} catch (error) {
			showMessageError(error.response.data.message)
		}
		setBeingOperator(false)
		setLoading(false)
	}

	const onDone = () => {
		dispatch(toggleChangeOperator(false))
	}

	return (
		<Loading
			loading={loadingUsersAllowed}
			type="cover"
		>
			<div>
				<div className="text-center mb-6">
					<h4 className="mb-2">{t('ticket_change_operator')}</h4>
					<p>{t('ticket_change_operator_label')}</p>
				</div>
				<Input
					ref={inputRef}
					prefix={<HiOutlineSearch className="text-lg" />}
					onChange={onSearch}
					placeholder={t('search')}
				/>
				<div className="mt-4">
					<p className="font-semibold uppercase text-xs mb-4">{operatorList.length} {t('ticket_available_operators')}</p>
					<div className="overflow-y-auto h-80 mb-6">
						<ScrollBar>
							{
								operatorList.map(user => (
									<div key={user.id} className="py-3 pr-5 rounded-lg flex items-center justify-between">
										<div className="flex items-center gap-2">
											<AvatarProfile className={'mr-1 rtl:ml-1 cursor-pointer'} user={user} />
											<div>
												<p className="heading-text font-bold">{user.name} {user.lastname}</p>
												<p>{user.email}</p>
											</div>
										</div>
										{
											isOperator(user.id)
												? <span className="heading-text font-bold">{t('operator')}</span>
												: <Button
													size="xs"
													onClick={() => onChangeOperator(user.id)}
													loading={loading && beingOperator == user.id}>
													{t('assign')}
												</Button>
										}
									</div>
								))
							}
						</ScrollBar>
					</div>
					<Button block variant="solid" onClick={onDone}>{t('close')}</Button>
				</div>
			</div>
		</Loading>
	)
}

export default ChangeOperatorContent