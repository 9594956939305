import ApiService from "./ApiService"


export async function apiGetTicketScheduleList(params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
    })
}

export async function apiGetTicketScheduleById(id) {
    return ApiService.fetchData({
        url: `/ticket_schedule/${id}`,
        method: 'get',
    })
}

export async function apiPostTicketSchedule(data) {
    return ApiService.fetchData({
        url: '/ticket_schedule',
        method: 'post',
        data
    })
}

export async function apiPutTicketSchedule(id, data) {
    return ApiService.fetchData({
        url: `/ticket_schedule/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteTicketSchedule(id) {
    return ApiService.fetchData({
        url: `/ticket_schedule/${id}`,
        method: 'delete',
    })
}