import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiMe, apiSignIn, apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuerySignIn from './useQuery'
import usePermissions from './usePermissions'

function useAuth() {

	
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuerySignIn()

	const { parsePermissions, parseRoles } = usePermissions()

	const { token, signedIn } = useSelector((state) => state.auth.session)

	const signIn = async ({ email, password }) => {
		try {
			const resp = await apiSignIn({ email, password })
			if (resp.data) {
				const { access_token } = resp.data
				dispatch(onSignInSuccess(access_token))
				const userMe = await apiMe(access_token)
				const { data } = userMe.data
				resp.data.user = {
					id: data.id,
					uuid: data.uuid,
					avatar: data.image,
					userName: data.name,
					userLastname: data.lastname,
					email: data.email,
					permissions: parsePermissions(data.permissions),
					authority: parseRoles(data.roles),
					haveNotifications: false
				}
				if (resp.data.user) {
					dispatch(setUser(resp.data.user || {
						avatar: '',
						userName: 'Anonymous',
						authority: ['USER'],
						email: ''
					}))
				}
				
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: '',
					access_token: access_token
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
				errors: errors
			}
		}
	}
   
	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
		localStorage.clear()
	}

	const signOut = async () => {
		try {
			await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signOut
	}
}

export default useAuth