
import React, { useEffect } from 'react'
import { HiOutlineClipboard, HiOutlineDownload } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { TicketSection } from './TicketContent'
import { useState } from 'react'
import { Button, Card, Tag, Tooltip } from 'components/ui'
import FileItemCard from 'components/viewComponents/FileItemCard'
import { apiGetQuestionGroupById } from 'services/QuestionGroupService'
import { DATE, FILE, TITLE } from 'constants/app.constant'
import { dateFormat } from 'utils/storeHube/helpersDate'
import ReactHtmlParser from 'react-html-parser'


const TicketAnswers = ({ ticket_answer, question_group, ticket_answer_attempt }) => {


    const { t } = useTranslation()
    const [questionGroup, setQuestionGroup] = useState()
    const [answers, setAnswers] = useState([])
    const [distanceAndDecice, setDistanceAndDevice] = useState()


    useEffect(() => {
        if (ticket_answer_attempt) setDistanceAndDevice(ticket_answer_attempt.find((item) => item.question_group_id === question_group.id))
    }, [ticket_answer_attempt])


    useEffect(() => {
        if (question_group) {
            if (question_group?.id !== ticket_answer[0]?.question_group_id) {
                apiGetQuestionGroupById(ticket_answer[0]?.question_group_id).then((res) => {
                    setQuestionGroup(res.data.data)
                })
            } else {
                setQuestionGroup(question_group)
            }
        }
    }, [question_group])


    const getIncidents = (q, a) => {
        let question = q
        if (q?.question_option?.length) {
            question = q.question_option.find(qo => qo.id === a.question_option_id)
        }
        return question.incidents
    }

    const getNameAnswerArray = (inputArray) => {

        return inputArray.map(obj => {
            const answer = obj.answer;
            const questionType = obj.question.question_type_id;

            if (questionType === FILE) {
                return {
                    name: obj.answer.question,
                    id: obj.question.id,
                    answer: {
                        id: answer.id,
                        size: parseInt(answer.size),
                        original_name: answer.original_name,
                        extension: answer.extension,
                        file: answer.file,
                        rotate: answer.rotate
                    },
                    question_type: questionType,
                    priority: obj.question.priority,
                };
            } else {
                return {
                    name: obj.answer.question ? obj.answer.question : obj.answer.name,
                    id: obj.question.id,
                    answer: {
                        answers: answer.value,
                        incidents: getIncidents(obj.question, obj.answer)
                    },
                    question_type: questionType,
                    priority: obj.question.priority,
                };
            }
        })
            .sort((a, b) => a.priority - b.priority);
    };

    function groupByName(arr) {

        const result = {};
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (result[item.id]) {
                result[item.id].answers.push(item.answer);
            } else {
                result[item.id] = { name: item.name, answers: [item.answer], question_type: item.question_type, priority: item.priority };
            }
        }

        const sortedValues = Object.values(result).sort((a, b) => a.priority - b.priority);
        return sortedValues;
    }

/*     const matchAnswersToQuestions = (answers, questions) => {
        let matchedAnswers = [];
        for (let i = 0; i < answers.length; i++) {
            for (let j = 0; j < questions.length; j++) {
                if (answers[i].question_id === questions[j].id) {
                    matchedAnswers.push({ answer: answers[i], question: questions[j] });
                    break;
                }
            }
        }
        return getNameAnswerArray(matchedAnswers)
    } */


        const matchAnswersToQuestions = (answers, questions) => {
            let matchedAnswers = [];
            for (let i = 0; i < answers.length; i++) {
                for (let j = 0; j < questions.length; j++) {
                    if (answers[i].question_id === questions[j].id) {
                        // Validar que si el question_type es 8 y visible es false, no se incluya
                        if (questions[j].question_type_id === 8 && answers[i].visible === false) {
                            continue; // Salta este par de respuesta y pregunta
                        }
                        matchedAnswers.push({ answer: answers[i], question: questions[j] });
                        break;
                    }
                }
            }
            return getNameAnswerArray(matchedAnswers);
        }
        

    useEffect(() => {
        questionGroup && setAnswers(groupByName(matchAnswersToQuestions(ticket_answer, questionGroup?.questions)))
    }, [questionGroup])



    return (
        <TicketSection title={t("answers")} icon={<HiOutlineClipboard />}>
            <div className=" flex flex-col grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                <h5>{questionGroup?.name}</h5>
                {distanceAndDecice && <span className=' capitalize '>{t("device")}: {distanceAndDecice.device}</span>}
                {distanceAndDecice && <span>{t("distance")}: {distanceAndDecice.distance}</span>}

                {
                    answers.map((answer, i) => (
                        <div key={i}>
                            <div className='flex items-center mb-3 gap-2'>
                                <p className={`text-gray-700 dark:text-gray-200 font-semibold ${answer.question_type === 8 ? 'text-base' : 'text-xs'}`}>
                                    {answer.name}
                                </p>

                            </div>
                            {answer.answers.length > 0 && answer.answers.map((r, i) => (
                                <div className='flex mb-2 items-center gap-2' key={i}>
                                    <span>{answer.question_type === DATE ? dateFormat(r.answers, 'DD-MM-YYYY') : ReactHtmlParser(r.answers)}</span>

                                    {r.incidents &&
                                        <Tag className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-0 rounded text-xs p-1">
                                            {t('incidence')}
                                        </Tag>
                                    }
                                </div>
                            ))}

                            {answer.question_type === FILE &&
                                <div className='flex flex-wrap gap-2'>
                                    {answer?.answers?.length > 0 &&
                                        answer?.answers.map(answer => (
                                            <Card
                                                className="w-[165px]"
                                                bodyClass="px-2 pt-2 pb-1"
                                                key={answer.original_name}
                                            >
                                                <FileItemCard className="w-[50px]" file={answer} />
                                                <div className="mt-1 flex justify-between items-center">
                                                    <span className="text-xs">{(parseInt(answer.size) / 1024).toFixed(2)} KB</span>
                                                    <div className="flex items-center">
                                                        <Tooltip title={t('download')}>
                                                            <a href={answer.file} download target="_blank">
                                                                <Button className="mr-1 rtl:ml-1" variant="plain" size="xs" icon={<HiOutlineDownload />} />
                                                            </a>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))
                                    }
                                </div>
                            }

                        </div>
                    ))

                }
            </div>
        </TicketSection>
    )
}

export default TicketAnswers