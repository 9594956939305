import ApiService	 from "./ApiService"

export async function apiGetKnowledgeCategory (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiPostapiGetKnowledgeCategory(data) {
    return ApiService.fetchData({
        url: `/knowledge_categories`,
        method: 'post',
        data
    })
}

export async function apiPutapiGetKnowledgeCategory (id, data) {
    return ApiService.fetchData({
        url: `/knowledge_categories/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteapiGetKnowledgeCategory (id) {
    return ApiService.fetchData({
        url: `/knowledge_categories/${id}`,
        method: 'delete',
    })
}

export async function apiGetArticleCategory (params){
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}
