import { apiGetTicketScheduleList } from "services/TicketScheduleService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


export const getTicketSchedule = createAsyncThunk('ticketSchedule/get', async (data) => {
    const response = await apiGetTicketScheduleList(data)
    return response.data
})


export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 30,
    query: '/ticket_schedule',
}

const initialState = {
    ticketScheduleList: [],
    ticketScheduleSelected: false,
    ticketScheduleChildList: [],
    loadingTicketSchedule: true,
    eventChild: false,
    tableData: initialTableData
}


const getEventsFromSchedule = (list = []) => {

    const childs = list.reduce((acc, ev) => {
        if (ev.events?.length) acc.push(...ev.events)
        return acc
    }, [])

    return { childs, list }
}


const dataSlice = createSlice({
    name: 'ticketSchedule/data',
    initialState,
    reducers: {
        setTicketScheduleList: (state, action) => {
            state.ticketScheduleList = action.payload
        },
        setTicketScheduleChildList: (state, action) => {
            state.ticketScheduleChildList = action.payload
        },
        setTicketScheduleSelected: (state, action) => {
            state.ticketScheduleSelected = action.payload
            state.eventChild = action.payload.parent_id === null ? false : true
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        }
    },
    extraReducers: {
        [getTicketSchedule.pending]: (state) => {
            state.loadingTicketSchedule = true
        },
        [getTicketSchedule.fulfilled]: (state, action) => {
            const { data, meta } = action.payload
            if(meta){
                state.tableData.total = meta.total
                state.tableData.pageIndex = meta.current_page
                state.tableData.pageSize = meta.per_page
            }

            const { childs, list } = getEventsFromSchedule(data)
            state.ticketScheduleChildList = childs
            state.ticketScheduleList = list
            state.loadingTicketSchedule = false
        },

    }
})

export const {
    setTicketScheduleList,
    setTicketScheduleChildList,
    setTicketScheduleSelected,
    setTableData
} = dataSlice.actions

export default dataSlice.reducer