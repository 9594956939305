import { Badge, toast } from "components/ui";
import AvatarProfile from "components/ui/Avatar/AvatarProfile";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHaveNotifications } from "store/auth/userSlice";
import { inventoryStatusColor } from "utils/storeHube/statusColor";
import { getTickets, resetFilterSelected } from "views/tickets/store/dataSlice";
import { ConfirmNotification, pusherContext } from ".";


const TicketNotification = () => {

    const { t } = useTranslation()
    const pusherChannel = useContext(pusherContext)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userAuth = useSelector(store => store.auth.user)

    const fetchTickets = () => {
        dispatch(resetFilterSelected())
        dispatch(getTickets({ query: 'tickets?filter[ticket_status_id]=1,2,7,8', pageIndex: 1 }))
        navigate('/tickets/inbox')
    }


    const listeningEvents = () => {

        pusherChannel?.bind("ticket-created", function (data) {
            dispatch(setHaveNotifications(true))
            const { ticket } = data.payload
            const { pathname } = window.location
            const pathnameTicket = pathname === ('/tickets/inbox')
            if (ticket.owner_id !== userAuth.id) {
                toast.push(
                    <ConfirmNotification
                        onConfirm={() => fetchTickets()}
                        duration={15000}
                    >
                        <div>
                            <p>{t('new_tickets_created')}</p>
                            <p>{pathnameTicket ? t('update_ticket_list_question') : t('go_to_tickets_question')}</p>
                        </div>
                    </ConfirmNotification>
                )
            }
        });


        pusherChannel?.bind("ticket-new-message", function (data) {
            const { payload, user } = data
            const { ticket_reply } = payload
           if (ticket_reply.user_id !== userAuth.id) {
                toast.push(
                    <ConfirmNotification
                        title={`${t('new_comment_on_ticket')} #${ticket_reply.ticket_id}`}
                        //icon={<AvatarProfile user={user} size={25} />}
                        textConfirm={t('ticket_watch')}
                        onConfirm={() => navigate(`/tickets/inbox?ticket=${ticket_reply.ticket_id}`)}
                        duration={15000}

                    >
                        <p className="font-semibol text-indigo-600">{ticket_reply.message}</p>
                    </ConfirmNotification>
                )
            }
        });

        pusherChannel?.bind("ticket-change-status", function (data) {
            const { payload, user } = data
            const { ticket } = payload
            if (user.id !== userAuth.id) {
                toast.push(
                    <ConfirmNotification
                        title={t('ticket_status change')}
                        textConfirm={t('ticket_watch')}
                        onConfirm={() => navigate(`/tickets/inbox?ticket=${ticket.id}`)}
                        duration={15000}

                    >
                        <div className="flex items-center gap-1">
                            <p>{t('ticket_capitalize')}</p>
                            <p className="font-semibol text-indigo-600">#{ticket.id}</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <p>{t('hasChangedState')}</p>
                            <Badge className={inventoryStatusColor[ticket.ticket_status_id].dotClass} />
                            <span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{inventoryStatusColor[ticket.ticket_status_id].label}</span>
                        </div>

                    </ConfirmNotification>
                )
            }
        });
    }

    useEffect(() => {
        listeningEvents()
    }, [])
}

export default TicketNotification