import React, { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = forwardRef((props, ref) => {
	// Define los tamaños de fuente disponibles
	const toolbarOptions = [
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		[{ 'size': ['small', false, 'large'] }],  
		['bold', 'italic', 'underline',],   
		[{ color: [] }],
		['link', 'image',],
		[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
		[{ 'align': [] }],
		['clean']
	];

	const modules = {
		toolbar: toolbarOptions
	};

	return (
		<div className="rich-text-editor">
			<ReactQuill ref={ref} {...props} modules={props.modules ? props.modules : modules} />
		</div>
	);
});

export default RichTextEditor;
