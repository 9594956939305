import { Button, Dialog, Dropdown, FormContainer, FormItem, Input, Skeleton } from "components/ui";
import {
    HiLink,
    HiOutlinePencilAlt
} from 'react-icons/hi'

import * as Yup from 'yup'
import { useSelector } from "react-redux"
import { setDialogView, toggleLinkTicket, toggleTicketDialog } from "../store/stateSlice"
import { useDispatch } from "react-redux"
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { apiLinkTicket } from "services/TicketsService";
import { showMessageError, showNotification } from "utils/storeHube/helpers";
import { getTicketById } from "../store/dataSlice";
import { setParentIdForTicket } from "../store/globalSlice";
import { STORE_HUB } from "components/ui/utils/constant";
import usePermissions from "utils/hooks/usePermissions";




const LinkTicketDialog = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { showLinkingTicket } = useSelector(state => state.ticketList.state)
    const { selectedTicket } = useSelector((state) => state.ticketList.data)
    const { user } = useSelector(state => state.auth)


    const onDialogClose = () => {
        dispatch(toggleLinkTicket(false))
    }



    const onSubmit = async (formValue, setSubmitting, resetForm) => {

        try {
            const { ticket_id } = formValue
            const parent_id = selectedTicket?.id
            await apiLinkTicket(ticket_id, { parent_id })
            dispatch(getTicketById(parent_id))
            showNotification({ title: t('tickets_capitalize'), message: t('linked_ticket') })

        } catch (error) {
            showMessageError(error.response.data.message)
            setSubmitting(false)
        }

        setSubmitting(false)
        dispatch(toggleLinkTicket(false))

    }


    return (
        <Dialog
            isOpen={showLinkingTicket}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            width={300}
        >

            <div className="mt-8">
                <Formik
                    initialValues={{ ticket_id: '' }}
                    validationSchema={
                        Yup.object().shape({
                            ticket_id: Yup.number().required(t('validateForm_required')),
                        })
                    }
                    onSubmit={(values, { resetForm, setSubmitting, }) => {
                        onSubmit(values, setSubmitting, resetForm)
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ values, touched, errors, resetForm, isSubmitting }) => (
                        <Form>
                            <FormContainer>
                                <FormItem
                                    label={t('ticket_id')}
                                    className="w-full mr-2"
                                    invalid={errors.ticket_id && touched.ticket_id}
                                    errorMessage={errors.ticket_id}
                                >
                                    <Field
                                        input
                                        name="ticket_id"
                                        type="number"
                                        placeholder={t('enter_ticket_id')}
                                        component={Input}
                                    />
                                </FormItem>

                                <Button
                                    loading={isSubmitting}
                                    className="w-full mt-2"
                                    variant="solid"
                                    type="submit"
                                >
                                    {t("save")}
                                </Button>
                            </FormContainer>
                        </Form>
                    )}
                </Formik>
            </div>
        </Dialog>
    )
}


const LinkTicketButton = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { checkPermission } = usePermissions()

    const { selectedTicket, loadingSelectedTicket } = useSelector((state) => state.ticketList.data)

    const tickets_can_link = checkPermission("tickets.can_link")

    const dropdownList = [
        { label: t('ticket_new'), value: 'add', icon: <HiOutlinePencilAlt /> },
        { label: t('existing_ticket'), value: 'link', icon: <HiLink /> },
    ]

    const addTicket = () => {
        dispatch(setParentIdForTicket(selectedTicket?.id))
        dispatch(setDialogView(STORE_HUB.ADD_TICKET))
        dispatch(toggleTicketDialog(true))
    }

    const linkTicket = () => {
        dispatch(toggleLinkTicket(true))
    }



    const onSelect = (e) => {
        switch (e) {
            case 'add':
                addTicket()
                break;
            case 'link':
                linkTicket()
                break;
            default:
                break;
        }
    }


    if (tickets_can_link) {
        return (
            <div className="flex justify-center items-center">
                {loadingSelectedTicket
                    ? <Skeleton className="w-12 h-4" />
                    : <Dropdown
                        placement="bottom-end"
                        renderTitle={
                            <Button
                                variant="plain"
                                size="xs"
                                icon={<HiLink />}
                            >
                                {t('link')}
                            </Button>
                        }
                    >
                        {dropdownList.map(item => (
                            <Dropdown.Item eventKey={item.value} key={item.value} onSelect={onSelect}>
                                <span className="text-lg">{item.icon}</span>
                                <span className="ml-2 rtl:mr-2">{item.label}</span>
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                }
                <LinkTicketDialog />
            </div>
        )
    }
}

export default LinkTicketButton