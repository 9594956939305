import React from 'react'
import QuestionTypeCheckBox from 'views/crud/formQuetionsGroup/QuestionTypeCheckBox'
import QuestionTypeDate from 'views/crud/formQuetionsGroup/QuestionTypeDate'
import QuestionTypeFilePicker from 'views/crud/formQuetionsGroup/QuestionTypeFilePicker'
import QuestionTypeMenu from 'views/crud/formQuetionsGroup/QuestionTypeMenu'
import QuestionTypeRadio from 'views/crud/formQuetionsGroup/QuestionTypeRadio'
import QuestionTypeSelector from 'views/crud/formQuetionsGroup/QuestionTypeSelector'
import QuestionTypeText from 'views/crud/formQuetionsGroup/QuestionTypeText'
import QuestionTypeTextArea from 'views/crud/formQuetionsGroup/QuestionTypeTextArea'
import QuestionTypeTitle from 'views/crud/formQuetionsGroup/QuestionTypeTitle'

const QuestionGenerator = ({ questions, onSubmit, createAnswers, errorMessage, onChange, onUploadFilesQuestion, removeFileState, value }) => {


    const componentMapper = question => {
        switch (question.question_type_id
        ) {
            case 1:
                return (
                    <QuestionTypeText
                        isAnswer
                        questionForAnswer={question}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        errorMessage={errorMessage}
                        defaultValue={value}
                    />
                );

            case 2:
                return (
                    <QuestionTypeSelector
                        isAnswer
                        questionForAnswer={question}
                        onChange={e => onChange(e.id)}
                        errorMessage={errorMessage}
                        defaultValue={value}
                    />
                );

            case 3: //deshabilitada
                return (
                    <QuestionTypeCheckBox
                        isAnswer
                        questionForAnswer={question}
                        createAnswers={createAnswers}
                        onChange={onChange}
                        errorMessage={errorMessage}
                        defaultValue={value && value}
                    />
                );

            case 4:
                return (
                    <QuestionTypeRadio
                        isAnswer
                        questionForAnswer={question}
                        onChange={e => onChange(e)}
                        errorMessage={errorMessage}
                        defaultValue={value}
                    />
                );
            case 5:
                return (
                    <QuestionTypeTextArea
                        isAnswer
                        questionForAnswer={question}
                        onChange={onChange}
                        errorMessage={errorMessage}
                        defaultValue={value}
                    />
                );
            case 6:
                return (
                    <QuestionTypeDate
                        isAnswer
                        questionForAnswer={question}
                        onChange={onChange}
                        errorMessage={errorMessage}
                        defaultValue={value && new Date(value)}
                    />
                );
            case 7:
                return (
                    <QuestionTypeMenu
                        isAnswer
                        questionForAnswer={question}
                        onChange={e => onChange(e.id)}
                        errorMessage={errorMessage}
                        defaultValue={value}
                    />
                );
            case 8:
                return (
                    <QuestionTypeTitle
                        isAnswer
                        questionForAnswer={question}
                        onChange={onChange}
                        errorMessage={errorMessage}
                    />
                );
            case 9:
                return (
                    <QuestionTypeFilePicker
                        isAnswer
                        questionForAnswer={question}
                        onChange={onChange}
                        errorMessage={errorMessage}
                        //onUploadFilesQuestion={onUploadFilesQuestion}
                        //removeFileState={removeFileState}
                        defaultValue={value}
                    />
                );
            default:
                return;
        }
    };
    return (
        componentMapper(questions)
    )
}

export default QuestionGenerator