import ApiService from "./ApiService"


export async function apiGetTicketList (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiGetTicketById (id) {
    return ApiService.fetchData({
        url: `/tickets/${id}`,
        method: 'get',
    })
}

export async function apiDeleteTicket (id) {
    return ApiService.fetchData({
        url: `/tickets/${id}`,
        method: 'delete',
    })
}


export async function apiPostTicket (data) {
    return ApiService.fetchData({
        url: `/tickets`,
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data
    })
}

export async function apiPostAnswerTicket (id, data) {
    return ApiService.fetchData({
        url: `/tickets/${id}/answers`,
        method: 'post',
        data
    })
}


export async function apiPutTicket (id, data) {
    return ApiService.fetchData({
        url: `/tickets/${id}`,
        method: 'put',
        data
    })
}


export async function apiTicketReplies (id, data) {
    return ApiService.fetchData({
        url: `/tickets/${id}/replies`,
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data
    })
}

export async function apiTicketFavorite (id) {
    return ApiService.fetchData({
        url: `/tickets/${id}/favorite`,
        method: 'post',
    })
}

export async function apiGetTicketCategories(params) {
    const {query} = params
    return ApiService.fetchData({
        url: query ?? '/tickets/categories',
        method: 'get',
    })
}
export async function apiGetTicketCategoriesCrud() {
    return ApiService.fetchData({
        url: '/tickets_categories',
        method: 'get',
    })
}



export async function apiGetTicketStatus() {
    return ApiService.fetchData({
        url: '/tickets/status',
        method: 'get',
    })
}

export async function apiChangeSubscriptionTicket(id){
    return ApiService.fetchData({
        url: `/tickets/${id}/subscribe`,
        method: 'post'
    })
}

export async function apiTicketExpiration (data) {
    return ApiService.fetchData({
        url: `/tickets/expiration`,
        method: 'post',
        data
    })
}
export async function apiGetUsersAndStores() {
    return ApiService.fetchData({
        url: '/users/teams',
        method: 'get',
    })
}

export async function apiGetAllUsers(params) {
    const {query} = params
    return ApiService.fetchData({
        url: query ,
        method: 'get',
    })
}

export async function apiLinkTicket(id, data) {
    return ApiService.fetchData({
        url: `/tickets/${id}/link`,
        method: 'post',
        data
    })
}



//OLDS

export async function apiGetSalesDashboardData (data) {
    return ApiService.fetchData({
        url: '/tickets',
        method: 'get',
        data
    })
}

export async function apiGetSalesProducts (data) {
    return ApiService.fetchData({
        url: '/sales/products',
        method: 'post',
        data
    })
}

export async function apiDeleteSalesProducts (data) {
    return ApiService.fetchData({
        url: '/sales/products/delete',
        method: 'delete',
        data
    })
}

export async function apiPutSalesProduct (data) {
    return ApiService.fetchData({
        url: '/sales/products/update',
        method: 'put',
        data
    })
}

export async function apiCreateSalesProduct (data) {
    return ApiService.fetchData({
        url: '/sales/products/create',
        method: 'post',
        data
    })
}

export async function apiGetSalesOrders (params) {
    return ApiService.fetchData({
        url: '/sales/orders',
        method: 'get',
        params
    })
}

export async function apiDeleteSalesOrders (data) {
    return ApiService.fetchData({
        url: '/sales/orders/delete',
        method: 'delete',
        data
    })
}

export async function apiGetSalesOrderDetails (params) {
    return ApiService.fetchData({
        url: '/sales/orders-details',
        method: 'get',
        params
    })
}

export async function apiRotateImage (id, data) {
    return ApiService.fetchData({
        url: `/tickets/answer_file/${id}/`,
        method: 'post',
        data
    })
}
