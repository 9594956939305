import React, { useRef, useState } from 'react'
import { Input, Button,  Spinner,   Tooltip, Badge} from 'components/ui'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { handleNameChange, } from './helpers'
import { FiSave } from 'react-icons/fi'

const QuestionTypeTitle  = ({ handleAddNewQuestion, questionTypeId, question, edit, handleRemoveQuestion, isAnswer, questionForAnswer, onChange, errorMessage }) => {

    const { t } = useTranslation()
  
    const [newOptionEdit, setNewOptionEdit] = useState(false)
    const [nameQuestion, setNameQuestion] = useState(question ? question.name : "")
    const [uniqueUuid, setUniqueUuid] = useState(question ? (question.uuid || question.uuid2) : "")
    const [optionListModified, setOptionListModified] = useState(question ? question.question_option : [])
    const [questionType_id, setQuestionTypeId] = useState(question ? question.question_type_id : questionTypeId)
    const [questionRequired, setQuestionRequired] = useState(question ? question.required : false)
    const [loading, setLoading] = useState(false)
    const [questionTag, setQuestionTag] = useState(question && question.tags ? question.tags[0] : null)
  
    const inputRef = useRef(null)
  
    useEffect(() => {
      if (newOptionEdit) {
        inputRef.current.focus()
      }
    }, [newOptionEdit])
  
  
  
    const updateQuestions = () => {
      setLoading(true)
      handleAddNewQuestion(nameQuestion, optionListModified, questionType_id, questionRequired, uniqueUuid)
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  
    useEffect(()=>{
      !isAnswer &&   updateQuestions()
    }, [optionListModified, nameQuestion, questionRequired])
  
  
  
    return (
      <>
        {
          isAnswer ?
            <>
              <p className=' text-base font-bold'>{questionForAnswer && questionForAnswer.name}</p>
            </>
            :
            <>
              <Input onChange={(event) => handleNameChange(setNameQuestion, event)} placeholder={t("question")} value={nameQuestion} />
              {edit && questionTag &&
              <div className=" flex items-center mr-2 rtl:ml-2 mt-3 gap-2">
                <p className=' capitalize '>{t("tag")} : </p>
                <div className="flex items-center gap-2 border rounded-lg px-1">
                  <Badge style={{ "backgroundColor": `${questionTag.color}` }} />
                  <span className={`capitalize font-semibold `}>
                    {questionTag.name}
                  </span>
                </div>
              </div>
            }
              <div className='mt-4'>
                <>
                  
                  <div className='flex items-center mb-3 justify-end'>
                    
                    {
                      edit &&
                      <>
                        <div className='mr-5 ' onClick={() => handleRemoveQuestion(question)}>
                          <Tooltip title="Borrar pregunta">
                            <HiOutlineTrash className=' text-lg ' />
                          </Tooltip>
                        </div>
                        <div className='mr-5' onClick={() => updateQuestions()}>
                          <Tooltip title="Guardar cambios">
                            {loading ? <Spinner /> : <FiSave className=' text-lg ' />}
                          </Tooltip>
                        </div>
                      </>
  
                    }
                  </div>
                  {
                    !edit && <Button
                      block
                      className="border-dashed mb-3"
                      onClick={() => handleAddNewQuestion(nameQuestion, optionListModified, questionType_id, questionRequired, uniqueUuid)}
                    >
                      {t("save_question")}
                    </Button>
                  }
                </>
  
              </div>
            </>
  
        }
  
      </>
    )
  }

export default QuestionTypeTitle