import { createSlice } from "@reduxjs/toolkit"


export const SCHEDULE_FORM = 'schedule_form'
export const SCHEDULE_PAYLOAD = 'schedule_payload'

const initialState = {
    openModal: false,
    openDeleteModal: false,
    modalView: SCHEDULE_FORM
}

const stateSlice = createSlice({
    name: "ticketSchedule/data",
    initialState,
    reducers: {
        setOpenModalTicketSchedule: (state, action) => {
            const { open, view } = action.payload
            state.openModal = open
            state.modalView = view ?? SCHEDULE_FORM
        },
        setOpenDeleteModal: (state, action) => {
            state.openDeleteModal = action.payload
        }
    }
})

export const {
    setOpenModalTicketSchedule,
    setOpenDeleteModal
} = stateSlice.actions

export default stateSlice.reducer
