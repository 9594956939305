import { ApolloClient, InMemoryCache } from '@apollo/client';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import deepParseJson from 'utils/deepParseJson';

const API_URL = process.env.REACT_APP_API_HOST

const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
const persistData = deepParseJson(rawPersistData)

const accessToken = persistData.auth.session.token


export const clientGraphql = new ApolloClient({
    uri: `${API_URL}/graphql/tenant?`,
    cache: new InMemoryCache(),
    headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : "",
    }
});