import ApiService	 from "./ApiService"

export async function apiGetKnowledgeArticle (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiPostKnowledgeArticle(data) {
    return ApiService.fetchData({
        url: `/knowledges`,
        method: 'post',
        data
    })
}

export async function apiGetKnowledgeArticleById(id) {
    return ApiService.fetchData({
        url: `/knowledges/${id}`,
        method: 'get',
    })
}

export async function apiPutKnowledgeArticle(id, data) {
    return ApiService.fetchData({
        url: `/knowledges/${id}`,
        method: 'post',
        data
    })
}

export async function apiDeleteKnowledgeArticle (id) {
    return ApiService.fetchData({
        url: `/knowledges/${id}`,
        method: 'delete',
    })
}

export async function apiSearchKnowledgeArticle (params) {
    return ApiService.fetchData({
        url: `knowledges?filter[search]=${params}`,
        method: 'get',
    })
}