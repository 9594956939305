import { Dialog, Tooltip, Button, toast, Notification } from "components/ui";
import FileItem from "components/ui/Upload/FileItem";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { HiOutlineEye, HiOutlineRefresh, HiOutlineReply, HiOutlineZoomIn, HiOutlineZoomOut, HiRefresh } from 'react-icons/hi'
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { useTranslation } from 'react-i18next'
import { apiRotateImage } from "services/TicketsService";
import { statusResponse } from "constants/apiResponse.constant";
import { showMessageError } from "utils/storeHube/helpers";
import useQuery from "utils/hooks/useQuery";
import { useDispatch } from "react-redux";
import { getTicketById } from "views/tickets/store/dataSlice";




const FileItemCard = (props) => {

    const { t } = useTranslation()
    const query = useQuery()

    const idTicket = query.get('ticket')
    const dispatch = useDispatch()

    const [dialogIsOpen, setIsOpen] = useState(false)
    const [fileSelected, setFileSelected] = useState()
    const [rotation, setRotation] = useState(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    const openDialog = (file) => {
        setFileSelected(file)
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        setIsOpen(false)
    }

    const { extension, size, file, original_name, rotate, id } = props.file
    const allowedExtensions = ['zip', 'pdf', 'docx', 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    useEffect(() => {
        if (dialogIsOpen) {
            const retrievedPosition = { x: 0, y: 0 }; // Reemplaza con la posición real desde la base de datos
            setRotation(rotate);
            setPosition(retrievedPosition);
        }
    }, [dialogIsOpen]);

    useEffect(()=>{setRotation(rotate)}, [])

    const rotateImage = () => {
        setRotation((rotation + 90) % 360);
    };
    const handleZoomIn = () => {
        setScale(scale + 0.1); // Incrementa la escala en 0.1
    };

    const handleZoomOut = () => {
        if (scale > 0.2) setScale(scale - 0.1); // Decrementa la escala en 0.1
    };
    const handleEndZoom = () => {
        setScale(1); // Decrementa la escala en 0.1
    };
    const handleDragStart = (e) => {
        setIsDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleDrag = (e) => {
        if (isDragging) {
            const deltaX = e.clientX - dragStart.x;
            const deltaY = e.clientY - dragStart.y;
            setPosition({ x: position.x + deltaX, y: position.y + deltaY });
            setDragStart({ x: e.clientX, y: e.clientY });
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    const saveRotate = async () => {

        try {
            const { data, status } = await apiRotateImage(id, { "rotate": rotation })
            if (status === statusResponse.SUCCESS) {
                
                dispatch(getTicketById(idTicket))
                toast.push(
                    <Notification title={t('created')} type="success" duration={2500}>
                        {t('changes_saved')}
                    </Notification>
                )
            }
        } catch (error) {
            showMessageError(error.response.data.errors)
        }
    }

    return (
        <div className="mt-2">
            {
                allowedExtensions.includes(extension) ?
                    <div className="w-36" >
                        <FileItem file={{ size, type: extension }} />
                    </div>

                    : <div className="flex justify-start">
                        <img className="w-12 rounded " alt={extension} src={file} style={{
                            transform: `rotate(${rotation}deg) translate(${position.x}px, ${position.y}px) scale(${scale})`,
                        }} />
                        <div className="mt-1 ml-1  flex justify-between items-center">
                            <Tooltip title={t("can_view")}>
                                <Button onClick={() => openDialog(props.file)} className="mr-1 rtl:ml-1" variant="plain" size="xs" icon={<HiOutlineEye />} />
                            </Tooltip>
                        </div>


                    </div>
            }
            {original_name && <p className="truncate font-semibold text-gray-900 dark:text-gray-100">{original_name}</p>}

            {fileSelected &&
                <Dialog
                    isOpen={dialogIsOpen}
                    onClose={onDialogClose}
                    onRequestClose={onDialogClose}
                >
                    <div className="flex  items-center justify-center  bg-white w-full z-50">
                        <Tooltip title={t("rotate_image")}>
                            <Button
                                onClick={rotateImage}
                                variant="plain"
                                size="xl"
                                icon={<HiOutlineReply />}
                                className="cursor-pointer z-50 "
                            />
                        </Tooltip>
                        <Tooltip title={t("zoom_in_image")}>
                            <Button
                                onClick={handleZoomIn}
                                variant="plain"
                                size="xl"
                                icon={<HiOutlineZoomIn />}
                                className="cursor-pointer z-50 "
                            />
                        </Tooltip>
                        <Tooltip title={t("zoom_out_image")}>
                            <Button
                                onClick={handleZoomOut}
                                variant="plain"
                                size="xl"
                                icon={<HiOutlineZoomOut />}
                                className="cursor-pointer z-50"
                            />
                        </Tooltip>
                        <Tooltip title={t("reset_image")}>
                            <Button
                                onClick={handleEndZoom}
                                variant="plain"
                                size="xl"
                                icon={<HiOutlineRefresh />}
                                className="cursor-pointer z-50"
                            />
                        </Tooltip>
                        {
                            (rotate !== rotation) &&
                            <Button onClick={() => saveRotate()} className="mr-1 rtl:ml-1" variant="solid"
                                size="sm">
                                {t("save_changes")}
                            </Button>
                        }
                    </div>
                    <div
                        className="w-full h-[550px] relative overflow-hidden cursor-grab"
                        onMouseDown={handleDragStart}
                        onMouseMove={handleDrag}
                        onMouseUp={handleDragEnd}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                        }}
                    >
                        <img
                            src={fileSelected.file}
                            alt=""
                            className="max-w-full max-h-full mx-auto block transform"
                            style={{
                                transform: `rotate(${rotation}deg) translate(${position.x}px, ${position.y}px) scale(${scale})`,
                            }}
                        />
                        {/*  <ReactPanZoom
                                image={file}
                                alt="Image alt text"
                            /> */}
                    </div>


                </Dialog>
            }
        </div>
    )
}

export default FileItemCard