import {combineReducers} from "@reduxjs/toolkit"
import dataProfile from "./dataProfile"
import dataOpenHours from "./dataOpenHours"
import dataSettings from "./dataSettings"

const reducer = combineReducers({
    dataProfile, 
    dataOpenHours, 
    dataSettings

})

export default reducer