import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiMeProfile } from 'services/AuthService'

export const getInfoProfile =createAsyncThunk("data/profile", async (data)=>{
    const response =await apiMeProfile(data)
    return response.data
})

const dataSlice=createSlice({
    name: "profile",
    initialState:{
        profile:{}, 
        loading:false
    }, 

    reducers:{
        setProfileData:(state, action) =>{
            state.profile.data = action.payload
        }
    },

    extraReducers:{
        [getInfoProfile.fulfilled]:(state, action) =>{
            state.profile = action.payload
            state.loading =false
        },
        [getInfoProfile.pending]: (state)=>{
            state.loading = true
        }

    }

})

export const {setProfileData}=dataSlice.actions
export default dataSlice.reducer

