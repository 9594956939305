import ApiService from "./ApiService";


//cameras
export async function apiGetDevicesCameras (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiPostDeviceCamera (data) {
    return ApiService.fetchData({
        url: `/devices/cameras`,
        method: 'post',
        data
    })
}

export async function apiPutDeviceCamera (id, data) {
    return ApiService.fetchData({
        url: `/devices/cameras/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteDeviceCamera (id) {
    return ApiService.fetchData({
        url: `/devices/cameras/${id}`,
        method: 'delete',
    })
}

export async function apiStartLiveCamera (id) {
    return ApiService.fetchData({
        url: `/devices/cameras/${id}/start`,
        method: 'post',
        data: {}
    })
}

export async function apiStopLiveCamera (id) {
    return ApiService.fetchData({
        url: `/devices/cameras/${id}/stop`,
        method: 'post',
        data: {}
    })
}

export async function apiRebootCamera (id, data) {
    return ApiService.fetchData({
        url: `/devices/cameras/${id}/reboot`,
        method: 'post',
        data
    })
}

export async function apiSyncDatetimeCamera (id, data) {
    return ApiService.fetchData({
        url: `/devices/cameras/${id}/sync_datetime`,
        method: 'post',
        data
    })
}




//metrics

export async function apiGetDevicesMetrics (params) {
    const { query, queryMetrics } = params
    return ApiService.fetchData({
        url: queryMetrics,
        method: 'get',
        //params
    })
}

export async function apiPostDeviceMetric (data) {
    return ApiService.fetchData({
        url: `/devices/metrics`,
        method: 'post',
        data
    })
}

export async function apiPutDeviceMetric (id, data) {
    return ApiService.fetchData({
        url: `/devices/metrics/${id}`,
        method: 'put',
        data
    })
}

export async function apiGetDeviceMetricById (id) {
    return ApiService.fetchData({
        url: `/devices/metrics/${id}`,
        method: 'get',

    })
}

export async function apiDeleteDeviceMetric (id) {
    return ApiService.fetchData({
        url: `/devices/metrics/${id}`,
        method: 'delete',
    })
}

//agents
export async function apiGetDevicesAgents (params) {
    const { query, queryAgents } = params
    return ApiService.fetchData({
        url: queryAgents,
        method: 'get',
        //params
    })
}

export async function apiPostDeviceAgent (data) {
    return ApiService.fetchData({
        url: `/devices/agents`,
        method: 'post',
        data
    })
}

export async function apiPutDeviceAgent (id, data) {
    return ApiService.fetchData({
        url: `/devices/agents/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteDeviceAgent (id) {
    return ApiService.fetchData({
        url: `/devices/agents/${id}`,
        method: 'delete',
    })
}

export async function apiRestartAgent (id) {
    return ApiService.fetchData({
        url: `/devices/agents/${id}/restart`,
        method: 'post',
    })
}

export async function apiForceUploadAgent (id, data) {
    return ApiService.fetchData({
        url: `/devices/agents/${id}/force_upload`,
        method: 'post',
        data
    })
}