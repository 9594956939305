import { toUpper } from "lodash"
import { useSelector } from "react-redux"



const usePermissions = () => {

    const ADMIN = 'ADMIN'

    const { authority: roles, permissions } = useSelector((state) => state.auth.user)

    
    const parseRoles = (roles) => {
        let rolesList = []
        roles.map(r => rolesList.push(toUpper(r)))
        return rolesList
    }

    const parsePermissions = (permissions) => {
        let permissionsList = []
        permissions.map(p => permissionsList.push(p.name))
        return permissionsList
    }

    const findAction = (rolePermissions, action) => {
        const havePermision = rolePermissions.find(r => r === action)
        return havePermision ? true : false
    }


    const checkPermission = (action) => {
        const isAdmin = roles.some(r => r === ADMIN)
        const canAdminAccess = permissions.find(p => p === 'account.can_admin_access')
        if(isAdmin || canAdminAccess){
            return true
        }
        const havePermission = findAction(permissions, action);
        return havePermission
    }


    return {
        parsePermissions,
        checkPermission,
        parseRoles
    }

}

export default usePermissions