import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetParametersSettings } from 'services/ParametersService'

export const apiGetParameters = createAsyncThunk("data/settings_tenant", async (data)=>{
    const response = await apiGetParametersSettings(data)
    return response.data.data
})

const dataSlice=createSlice({
    name: "parameters/settings",
    initialState:{
        parameters:{}, 
        loading:false
    }, 

    reducers:{
        setParametersData:(state, action) =>{
            state.parameters.data = action.payload
        }, 
        updateParametersData:(state, action)=>{
            state.parameters = action.payload
        }

    },

    extraReducers:{
        [apiGetParameters.fulfilled]:(state, action) =>{
            state.parameters = action.payload
            state.loading =false
        },
        [apiGetParameters.pending]: (state)=>{
            state.loading = true
        }

    }

})

export const {setParametersData, updateParametersData}=dataSlice.actions
export default dataSlice.reducer

