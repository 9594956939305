import { gql } from '@apollo/client';


export const PARAMETERS_TENANT = gql`
  {
    parameter{
        key
        value
    }
    timezone{
      code
      id
    }

  }
`