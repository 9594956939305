import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import "../style/ticketInbox.css"
import { Tooltip, Button, Dropdown, Badge, Notification, toast, InputGroup, Tabs } from 'components/ui'
import {
	HiOutlineClipboardList,
	HiOutlinePaperClip,
	HiOutlineStar,
	HiStar,
	HiFlag,
	HiOutlineFlag,
	HiOutlineFolderDownload,
	HiOutlineClock,
	HiOutlineCalendar,
	HiOutlinePencilAlt,
	HiChevronDoubleUp,
	HiChevronDoubleDown,
	HiOutlineDuplicate,
	HiTag,
	HiOutlineExclamation,
	HiOutlineBan,
	HiOutlineTicket
} from 'react-icons/hi'
import { priorityStatusColor, inventoryStatusColor } from 'utils/storeHube/statusColor'
import { apiPutTicket, apiTicketFavorite, apiTicketReplies, apiChangeSubscriptionTicket } from 'services/TicketsService'
import { statusResponse } from 'constants/apiResponse.constant'
import { getUsersAllowed, setSelectedTicket, updateTicketList } from '../store/dataSlice'
import { deleteDuplicateData, showMessageError, setUserObject, convertUrlToLink, deleteDuplicateDataToFinal } from 'utils/storeHube/helpers'
import { dateFormat, timeDiff } from 'utils/storeHube/helpersDate'
import { useTranslation } from 'react-i18next'
import AvatarProfile from 'components/ui/Avatar/AvatarProfile'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import usePermissions from 'utils/hooks/usePermissions'
import { IconText } from 'components/shared'
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime";
import es from "dayjs/locale/es"
import TicketChangeStatusConfitmation from '../ticketList/TicketChangeStatusConfitmation'
import { USER_SYS } from 'constants/app.constant'
import ReactHtmlParser from 'react-html-parser'
import TicketAttachment from './TicketAttachment'
import TicketActivity from './TicketActivity'
import TabNav from 'components/ui/Tabs/TabNav'
import TabList from 'components/ui/Tabs/TabList'
import TabContent from 'components/ui/Tabs/TabContent'
import TicketAnswers from './TicketAnswers'
import TicketRecod from './TicketRecord'
import ChangeOperatorDialog from './ChangeOperatorDialog'
import { setSelectedTicketSlice, toggleChangeOperator, toggleDeleteConfirmation } from '../store/stateSlice'
import { apiGetTicketsCategoriesById } from 'services/TicketCategoryTypeServices'
import TicketQuestion from './TicketQuestion'
import TicketAddComment from './TicketAddComment'
import TicketIncidents from './TicketIncidents'
import ItemDeleteConfirmation from 'views/crud/components/ItemDeleteConfirmation'
import TicketDeleteConfirmation from '../ticketList/TicketDeleteConfirmation'
import useThemeClass from 'utils/hooks/useThemeClass'
import LinkTicketButton from './LinkTicketButton'
import { getAllUsers } from '../store/globalSlice'

dayjs.extend(relativeTime)
dayjs.locale(es);

export const TicketSection = ({ title, icon, children, titleSize: Title = 'h6', ticketClose }) => {

	return (
		<div className="flex mb-5">
			<div className="text-xl">{icon}</div>
			<div className="ml-2 rtl:mr-2 w-full">
				<div className="flex justify-between">
					<Title>{title}</Title>
				</div>
				{children}
			</div>
		</div>
	)
}

const TicketContent = ({ data }) => {


	const { t } = useTranslation()
	const navigate = useNavigate()
	const { textTheme } = useThemeClass()

	const loadingSelectedTicket = useSelector(state => state.ticketList.data.loadingSelectedTicket)

	const {
		id,
		description,
		is_favorite,
		created_at,
		expired_at,
		sample_since,
		sample_until,
		second_start,
		props,
		parent_id,
		external_id,
		external_code,
		incidence
	} = data

	const {
		owner,
		store,
		operator,
		ticket_subscriptions,
		ticket_reply,
		ticket_log,
		ticket_category_primary,
		ticket_status,
		ticket_attachment,
		ticket_answer,
		record,
		ticket_category_secondary,
		ticket_answer_attempt,
		incidents
	} = props


	const { checkPermission } = usePermissions()

	const tickets_can_close = checkPermission('tickets.can_close')
	const tickets_can_reopen = checkPermission('tickets.can_reopen')
	const tickets_can_update = checkPermission('tickets.can_update')
	const tickets_can_view_timeline = checkPermission('tickets.can_view_timeline')
	const tickets_can_cancel = checkPermission("tickets.can_cancel")


	const dispatch = useDispatch()
	const [selectedTab, setSelectedTab] = useState("activity")

	const user = useSelector(state => state.auth.user)
	const { ticketStatus, loadingGlobalTicket } = useSelector(state => state.ticketList.global)
	const { ticketList, selectedTicket, selectedTicketId, loadingUsersAllowed } = useSelector(state => state.ticketList.data)
	const haveSubscription = ticket_subscriptions.find(sub => sub.email === user.email)

	const NEW_TICKET = 1
	const ASSIGNED = 2
	const CANCELLED = 3
	const CLOSED = 4
	const DELETED = 5
	const SYS_CLOSED = 6
	const REOPEN = 7

	const canViewQuestionAndResponse = user?.id === owner?.id || user?.id === operator?.id

	const { pathname } = useLocation()

	const [questionGroup, setQuestionGroup] = useState(null)
	const [questionGroupId, setQuestionGroupId] = useState(null)
	const [questionLoading, setQuestionLoading] = useState(false)
	const [notificationQuestion, setNotificationQuestion] = useState(false)
	const [answerOpen, setAnswerOpen] = useState([])
	const [answerClose, setAnswerClose] = useState([])
	const [questionRequired, setQuestionRequired] = useState(null)
	const [questionGroupSecondary, setQuestionGroupSecondary] = useState(null)

	useEffect(() => { setQuestionLoading(false) }, [selectedTicketId, ticketList])

	useEffect(() => {
		dispatch(getUsersAllowed(store.id))
		checkOperatorAndChangeTicketStatus()
		setSelectedTab("activity")
	}, [selectedTicketId])


	const closeNotification = (key) => {
		toast.remove(key)
	}

	const closeNotificationQuestions = (key)=>{
		toast.remove(key)
	
	}

	
	const checkOperatorAndChangeTicketStatus = () => {
		toast.removeAll()
		setTimeout(async () => {
			if (operator?.id === user?.id && ticket_status?.id === NEW_TICKET && pathname === '/tickets/inbox') {
				const notification = await toast.push(
					<Notification title={t('change_status')} duration={10000}>
						<div>{t('change_status_label')}</div>
						<div className="text-right mt-3">
							<Button onClick={() => (setNewTicketStatus(ASSIGNED, id) & closeNotification(notification))} size="xs" variant="solid" className="mr-2">{t('confirm_capitalize')}</Button>
							<Button onClick={() => closeNotification(notification) } size="xs">{t('cancel_capitalize')}</Button>
						</div>
					</Notification>
				)
			}

		}, 500);
	}

	const setOptions = (option, color) => {

		if (option === CLOSED && !tickets_can_close) return {}
		if (option === REOPEN && !tickets_can_reopen) return {}

		const optionStatus = ticketStatus.find(status => status.id === option)
		return { ...optionStatus, color }
	}

	const filterTicketStatus = (allStatus, ticketStatus) => {

		const { id } = ticketStatus

		switch (id) {
			case NEW_TICKET:
				return setOptions(ASSIGNED, 'bg-amber-500')
			case ASSIGNED:
				return setOptions(CLOSED, 'bg-red-500')
			case REOPEN:
				return setOptions(CLOSED, 'bg-red-500')
			case DELETED:
				return setOptions(CLOSED, 'bg-red-500')
			case CANCELLED:
				return setOptions(REOPEN, 'bg-emerald-500')
			case CLOSED:
				return setOptions(REOPEN, 'bg-emerald-500')
			case SYS_CLOSED:
				return setOptions(REOPEN, 'bg-emerald-500')
			default:
				console.log(allStatus);
				break;
		}
	}


	const canChangeTicketStatus = () => {
		try {
			if (!loadingSelectedTicket) {
				const isOperator = operator?.id === user.id
				if (ticket_status.id === CLOSED) {
					const statusLog = ticket_log.findLast(l => l.ticket_status_id === CLOSED)
					const closedAgo = timeDiff(statusLog.created_at, 'hour')
					return isOperator && closedAgo <= 48
				}
				return isOperator
			}
		} catch (error) {
			return false
		}
	}

	const canChangeStatus = canChangeTicketStatus()
	const nextStatus = filterTicketStatus(ticketStatus, ticket_status)

	const [loadingFavorite, setLoadingFavorite] = useState(false)
	const [loadingStatus, setLoadingStatus] = useState(false)

	const setSubscriber = (ticket, userSub, addSub) => {
		let props = ticket.props
		const subscriptions = addSub ? [...props.ticket_subscriptions, userSub] : props.ticket_subscriptions.filter(s => s.email !== userSub.email)
		const ticketUpdateReply = { ...ticket, props: { ...props, ticket_subscriptions: subscriptions } }
		dispatch(setSelectedTicket(ticketUpdateReply))
	}


	const handleTicketClose = () => {
		//onTicketClose?.()
	}

	// cheque si el cuestionario solo tiene una pregunta y si es de tipo titulo. 
	const checkWithOutQuestionary = () => {
		if (questionGroup?.questions.length === 1 && questionGroup?.questions[0].question_type_id === 8) {
			return true
		}
	}
	// cheque si en las prehuntas hay alguna si es requerida
	const checkQuestionRequired = (questions) => {
		const questionIsRequired = questions.filter((item) => item.required === true)
		setQuestionRequired(questionIsRequired)
	}

	useEffect(() => {
		if (questionGroup) checkWithOutQuestionary()
	}, [questionGroup])

	useEffect(() => {
	/* 	if (!notificationQuestion && questionLoading && !checkWithOutQuestionary() && !answerClose.length > 0) {
			checkQuestionsClose(answerClose.length === 0)
		} */
		return () => {
			setNotificationQuestion(false)
		}
	}, [questionLoading]) 

	const checkQuestionsClose = (itemCheck) => {
		setTimeout(async () => {
			if (operator?.id === user?.id && questionGroup && itemCheck/* answerClose.length === 0 */) {
				setNotificationQuestion(true)
				const notification = await toast.push(
					<Notification className={"w-full"} title={t('questions')} duration={5000}>
						<div>{t("questionnaire_notification_text")}</div>
						<div className="text-right mt-3 w-full">
							<Button onClick={() => (setSelectedTab("questions") & closeNotificationQuestions(notification) & dispatch(toggleChangeOperator(false)))} size="xs" variant="solid" className="mr-2">{t('confirm_capitalize')}</Button>
							<Button onClick={() => closeNotificationQuestions(notification)} size="xs">{t('cancel_capitalize')}</Button>
						</div>
					</Notification>
				)
			}
		}, 100);
	}

	const setNewTicketStatus = async (status_id, ticketId) => {
		setLoadingStatus(true)
		try {
			if (operator?.id === user?.id && questionGroup && questionRequired.length > 0 && !answerClose.length > 0 && status_id === 4) {
				checkQuestionsClose(questionRequired.length > 0)
			} else {
				const { status, data } = await apiPutTicket(ticketId, { ticket_status_id: status_id })
				if (status === statusResponse.SUCCESS) {

					const newTicketList = status_id !== 4 ? deleteDuplicateData(ticketList, data.data) : deleteDuplicateDataToFinal(ticketList, data.data)
					dispatch(updateTicketList(newTicketList))
					dispatch(setSelectedTicket(data.data))

					toast.push(
						<Notification title={t('updated')} type="success" duration={2500}>
							{t('ticket_successUpdate')}
						</Notification>
					)
				}
			}

		} catch (error) {
			showMessageError(error.response.data.message)
		}
		setLoadingStatus(false)
	}

	const changeIsFavorite = async () => {
		setLoadingFavorite(true)
		const ticket = { ...data, is_favorite: !is_favorite }

		try {
			const { status } = await apiTicketFavorite(id)
			if (status === statusResponse.SUCCESS) {
				const newTicketList = deleteDuplicateData(ticketList, ticket)
				dispatch(updateTicketList(newTicketList))
				dispatch(setSelectedTicket(ticket))
			}
		} catch (error) {
			console.log(error);
		}
		setLoadingFavorite(false)
	}

	const handleChangeSuscription = async () => {

		try {
			const res = await apiChangeSubscriptionTicket(id)
			if (res.status === statusResponse.SUCCESS) {
				setSubscriber(selectedTicket, setUserObject(user), haveSubscription ? false : true)
				toast.push(
					<Notification title={haveSubscription ? t('unsuscribe') : t('suscribe')} type="success" duration={2500}>
						{haveSubscription ? t('ticket_unsuscribe') : t('ticket_suscribe')}
					</Notification>
				)
			}
		} catch (error) {
			console.log(error);
		}
	}

	const handleCopyClick = () => {
		navigator.clipboard.writeText(window.location.href)
		toast.push(
			<Notification title={t('copied')} type="success" duration={1000} />
			, {
				placement: 'top-center'
			})
	}

	const handleTicketParent = () => {
		navigate(`/tickets/inbox?ticket=${parent_id}`)
	}
	const handleTabChange = (val) => {
		setSelectedTab(val)
	}

	useEffect(() => {
		const separateAnswers = (array, idOne, idTwo) => {
			let answersOpen = []
			let answersClose = []
			array.map((item) => {
				if (item.question_group_id === idOne) answersOpen.push(item)
				else answersClose.push(item)
			})
			setAnswerOpen(answersOpen)
			setAnswerClose(answersClose)
		}

		ticket_category_secondary && separateAnswers(ticket_answer, ticket_category_secondary?.question_group_primary_id, ticket_category_secondary?.question_group_secondary_id)
	}, [ticket_category_secondary])

	const sortQuestionsByPriority = (obj) => {
		const sortedObj = JSON.parse(JSON.stringify(obj));
		sortedObj.questions.sort((a, b) => a.priority - b.priority);
		return sortedObj;
	}


	useEffect(() => { setQuestionGroupId(null) }, [selectedTicketId])

	useEffect(() => {
		if (ticket_category_secondary && ticket_category_secondary.question_group_secondary/*  && !questionGroupId */) {
			apiGetTicketsCategoriesById(ticket_category_secondary?.id).then((res) => {
				const { data } = res
				if (res.data) {
					setQuestionGroupId(data.data.question_group_secondary_id);
					setQuestionGroupSecondary(data.data.question_group_secondary[0])
				}
			})
		} else setQuestionGroup(null)
	}, [ticket_category_secondary, ticketList /*  selectedTicketId */])

	useEffect(() => {
		if (questionGroupSecondary) {
			setQuestionGroup(sortQuestionsByPriority(questionGroupSecondary))
			checkQuestionRequired(questionGroupSecondary?.questions)
			setQuestionLoading(true)
		}
	}, [questionGroupSecondary])

	useEffect(()=>{dispatch(getAllUsers())}, [])


	useEffect(() => {
		if (answerOpen && !canViewQuestionAndResponse && !tickets_can_view_timeline) {
			setSelectedTab("answers")
		}
	}, [answerOpen])

	const handeDeleteTicket = () => {
		if (operator?.id === user.id) {
			dispatch(setSelectedTicketSlice(data))
			dispatch(toggleDeleteConfirmation(true))
		} else {
			toast.push(
				<Notification title={t('change_status')} type="danger" duration={2000}>
					<div>{t('ticket_no_permission_cancel')}</div>
				</Notification>
			)
		}
	} 

	const changeOperator = ()=>{
		if (!notificationQuestion && questionLoading && !checkWithOutQuestionary() && !answerClose.length > 0) {
			checkQuestionsClose(answerClose.length === 0)
			setTimeout(()=>{dispatch(toggleChangeOperator(true))}, 500)
		}else dispatch(toggleChangeOperator(true))
		
	}

	return (
		<>
			<div className={pathname === '/tickets/inbox' ? 'overflow-hidden h-full items' : 'overflow-y-auto max-h-[500px]'} id="ticketContent">
				{
					tickets_can_cancel &&
					<div className='flex items-end'>

						<Button
							size="xs"
							icon={
								<HiOutlineBan />
							}
							onClick={() => handeDeleteTicket()}
						>

							<span className={`cursor-pointer p-2`} >
								{t("cancel")}
							</span>
						</Button>
					</div>
				}
				<TicketSection
					title={<div className='my-4 flex flex-col sm:flex-row items-start sm:items-center	'>
						<div className='flex items-center'>
							<Tooltip title={t('copy')} >
								<HiOutlineDuplicate
									className={'cursor-pointer text-xl'}
									onClick={handleCopyClick}
								/>
							</Tooltip>

							<span className="font-semibold text-sky-500 m-2">#{id}</span>
						</div>
						{ticket_category_primary?.label?.es}
						<Link to={`/stores/${store?.id}`} className={`ml-0 sm:ml-3 underline cursor-pointer select-none font-semibold hover:${textTheme}`}>
							{store?.name}
						</Link>
					</div>}
					titleSize="h5"
					ticketClose={handleTicketClose}
				>
					<div className="flex justify-start sm:justify-end gap-2 px-3">
						 <LinkTicketButton/> 
						<InputGroup size="sm">
							<Button
								size="xs"
								icon={
									<span className="text-amber-500">{is_favorite ? <HiStar /> : <HiOutlineStar />}</span>
								}
								onClick={!loadingFavorite && changeIsFavorite}
							>
								<span className="hidden text-xs sm:block">{'Favorito'}</span>
							</Button>
							<Button
								size="xs"
								icon={
									<span className="text-red-500">{haveSubscription ? <HiFlag /> : <HiOutlineFlag />}</span>
								}
								onClick={() => handleChangeSuscription()}
							>
								<span className="hidden text-xs  sm:block">{haveSubscription ? t('unfollow') : t('follow')}</span>
							</Button>
						</InputGroup>
						<Dropdown
							disabled={!canChangeStatus}
							placement="bottom-end"
							renderTitle={
								<Button size="xs" icon={<HiOutlineFolderDownload />}>
									<div className="flex items-center">
										<Badge innerClass={`${inventoryStatusColor[ticket_status.id].dotClass}`} />
										<span className=" text-xs ml-2 rtl:mr-2">{inventoryStatusColor[ticket_status.id].label}</span>
									</div>
								</Button>
							}
						>
							<Dropdown.Item
								onSelect={() => setNewTicketStatus(nextStatus.id, id)}
								eventKey={nextStatus.id}
								key={nextStatus.name}
							>
								<div className="flex items-center">
									<Badge innerClass={`${nextStatus.color}`} />
									<span className="ml-2 rtl:mr-2">{nextStatus.label}</span>
								</div>
							</Dropdown.Item>
						</Dropdown>


					</div>
				</TicketSection>

				<TicketSection title={t('details')} icon={<HiOutlinePaperClip />}>
					<div className='mt-2 grid grid-cols-1 md:grid-cols-2 gap-3'>
						<div className="flex items-center h-8">
							<div className="ml-1 mr-2 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('assigned_to')}:</div>


							{operator
								? <Tooltip title={`${operator.name} ${operator.lastname}`}>
									<AvatarProfile className={'mr-2 rtl:ml-1 cursor-pointer'} user={operator} size={22} />
								</Tooltip>
								: <span className="font-semibold mr-2">{t('not_assigned')}</span>
							}

							{tickets_can_update && (
								<Button
									disabled={loadingUsersAllowed}
									size="sm"
									className="border-none"
									onClick={() => changeOperator()}
									icon={<HiOutlinePencilAlt size={20} />}
								/>
							)}
						</div>

						<div className="flex items-center h-8">
							<div className="ml-1 mr-2 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('createdBy')}:</div>
							<Tooltip title={owner ? `${owner.name} ${owner.lastname}` : `${USER_SYS.name} ${USER_SYS.lastname}`}>
								<AvatarProfile className={'mr-1 rtl:ml-1 cursor-pointer'} user={owner || USER_SYS} size={22} />
							</Tooltip>
						</div>
						<IconText icon={<HiOutlineCalendar className="text-lg" />} className='h-8'>
							<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('created')}: </span>
							<span className="font-semibold">{dateFormat(created_at.replace('.000000Z', ''), 'DD-MM-YYYY HH:mm')}</span>
						</IconText>
						<IconText icon={<HiOutlineClock className="text-lg" />} className='h-8'>
							<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('expires')}: </span>
							<span className="font-semibold">{expired_at ? dateFormat(expired_at.replace('.000000Z', '')) : t('without_date')}</span>
						</IconText>
						{
							ticket_category_primary &&
							<IconText icon={<HiTag className="text-lg" />} className='h-8'>
								<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('category')}: </span>

								<span className="font-semibold text-sky-500">{ticket_category_primary.parent.label.es}</span>
							</IconText>
						}

						{
							ticket_category_primary &&
							<IconText icon={ticket_category_primary.priority > 1 ? <HiChevronDoubleDown className="text-lg" /> : <HiChevronDoubleUp className="text-lg" />} className='h-8'>
								<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100" >{t('priority')}: </span>
								<span className={`font-semibold ${priorityStatusColor[ticket_category_primary.priority].textClass}`}>{priorityStatusColor[ticket_category_primary.priority].label}</span>
							</IconText>
						}

						{
							external_code &&
							<IconText icon={<HiOutlineTicket className="text-lg" />} className='h-8'>
								<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100" >{t('external_code')}: </span>
								<span className='font-semibold text-sky-500'>{external_code}</span>
							</IconText>
						}

						{
							sample_since &&
							<IconText icon={<HiOutlineCalendar className="text-lg" />} className='h-8'>
								<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('since')}: </span>
								<span className="font-semibold">{dayjs(sample_since).format('L LT')}</span>
							</IconText>
						}
						{
							sample_until &&
							<IconText icon={<HiOutlineCalendar className="text-lg" />} className='h-8'>
								<span className="ml-1 rtl:mr-1 font-semibold text-gray-900 dark:text-gray-100">{t('until')}: </span>
								<span className="font-semibold">{dayjs(sample_until).format('L LT')}</span>
							</IconText>
						}

					</div>
				</TicketSection>

				{
					parent_id && incidence && (
						<TicketSection title={t('incidence')} icon={<HiOutlineExclamation />}>
							<div className="mt-2">
								<div className=" ticketInbox  max-w-none"> {t("message_automatically_creted_incidents")} <span className=" cursor-pointer font-semibold text-sky-500 " onClick={handleTicketParent}>#{parent_id}</span> </div>
							</div>
						</TicketSection>
					)
				}
					{
					parent_id && !incidence && (
						<TicketSection title={t('linked_ticket')} icon={<HiOutlineExclamation />}>
							<div className="mt-2">
								<div className=" ticketInbox  max-w-none"> {t("message_linked_ticket")} <span className=" cursor-pointer font-semibold text-sky-500 " onClick={handleTicketParent}>#{parent_id}</span> </div>
							</div>
						</TicketSection>
					)
				}

				{
					description && (
						<TicketSection title={t('description')} icon={<HiOutlineClipboardList />}>
							<div className="mt-2">
								<div className="ql-editor  ticketInbox  max-w-none">{ReactHtmlParser(convertUrlToLink(description))}</div>
							</div>
						</TicketSection>
					)
				}

				<div>
					<Tabs value={selectedTab} onChange={handleTabChange} >
						<div className="flex  lg:items-center justify-between flex-col lg:flex-row gap-4">
							<TabList>
								{canViewQuestionAndResponse && questionGroup && !checkWithOutQuestionary() && <TabNav value="questions">{answerClose.length ? t('answers_closed') : t('questions')}</TabNav>}
								{!canViewQuestionAndResponse && answerClose?.length > 0 && <TabNav value="questions">{t('answers_closed')}</TabNav>}
								{tickets_can_view_timeline && <TabNav value="activity">{t('activity')}</TabNav>}
								{ticket_attachment?.length > 0 && <TabNav value="attachment">{t("attachments")}</TabNav>}
								{ticket_answer?.length > 0 && ticket_category_primary?.question_group_primary_id && <TabNav value="answers">{t("answers_open")}</TabNav>}
								{record && <TabNav value="record">{t("record")}</TabNav>}
								{incidents && incidents.length > 0 && <TabNav value="incidents">{incidence ? t('incidents') : t("linked_tickets")}</TabNav>}
							</TabList>
						</div>
						<div className="mt-4 mx-5">

							<TabContent value="questions">
								{
									answerClose && answerClose?.length > 0 ?

										<>
											<TicketAnswers ticket_answer={answerClose} question_group={ticket_category_secondary?.question_group_secondary} ticket_answer_attempt={ticket_answer_attempt} />
											<TicketAddComment ticket={data} disabled={loadingStatus} />

										</>
										:
										canViewQuestionAndResponse && questionGroup &&
										<TicketQuestion questionGroupSecondary={questionGroup} questionSecondaryGroupId={questionGroupId} ticketId={id} store={store} setNewTicketStatus={setNewTicketStatus} assigned={ASSIGNED} id={id} ticketStatus={ticket_status?.id} checkWithOutQuestionary={checkWithOutQuestionary} />
								}
							</TabContent>
							<TabContent value="activity">
								{
									tickets_can_view_timeline &&
									<TicketActivity ticket_reply={ticket_reply} ticket_log={ticket_log} ticket_answer={ticket_answer_attempt} loadingSelectedTicket={loadingSelectedTicket} />
								}
								<TicketAddComment ticket={data} disabled={loadingStatus} />
							</TabContent>
							<TabContent value="attachment">
								{
									ticket_attachment?.length > 0 &&
									<TicketAttachment ticket_attachment={ticket_attachment} />

								}
							</TabContent>
							<TabContent value="answers">
								{
									ticket_answer?.length > 0 && ticket_category_primary?.question_group_primary_id &&
									<TicketAnswers ticket_answer={answerOpen} question_group={ticket_category_primary?.question_group_primary} />

								}
							</TabContent>

							<TabContent value="record">
								{
									record &&
									<TicketRecod record={{ ...record, second_start }} />
								}
							</TabContent>

							<TabContent value="incidents">
								{
									incidents && incidents.length > 0 &&

									<TicketIncidents incidents={incidents} incidence={incidence} ticket={data} />
								}
							</TabContent>

						</div>
					</Tabs>
				</div>

			</div >
			<TicketDeleteConfirmation />
			<TicketChangeStatusConfitmation />
			<ChangeOperatorDialog />
		</>

	)
}

export default TicketContent