import { createSlice } from '@reduxjs/toolkit'
import { STORE_HUB } from 'components/ui/utils/constant'

const stateSlice = createSlice({
    name: 'ticketList/state',
    initialState: {
        changeStatusConfirmation: false,
        deleteConfirmation: false,
        selectedTicket: '',
        viewTicket: false,
        dialogView: '',
        changeOperatorView: false,
        inboxSideBarExpand: true,
        inboxMobileSideBarExpand: false,
        showLinkingTicket: false,
        sortedColumn: () => { },
    },
    reducers: {
        toggleChangeStatusConfirmation: (state, action) => {
            state.changeStatusConfirmation = action.payload
        },
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleChangeOperator: (state, action) => {
            state.changeOperatorView = action.payload
        },
        toggleLinkTicket: (state, action) => {
            state.showLinkingTicket = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedTicketSlice: (state, action) => {
            state.selectedTicket = action.payload
        },
        toggleTicketDialog: (state, action) => {
            state.viewTicket = action.payload
        },
        setDialogView: (state, action) => {
            state.dialogView = action.payload
        },
        setInboxSideBarExpand: (state, action) => {
            state.inboxSideBarExpand = action.payload
        },
        setInboxMobileSideBarExpand: (state, action) => {
            state.inboxMobileSideBarExpand = action.payload
        },
        openTicketForm: (state, action) => {
            state.dialogView =  STORE_HUB.ADD_TICKET
            state.viewTicket = true
        }

    },
})

export const {
    toggleChangeStatusConfirmation,
    toggleDeleteConfirmation,
    toggleChangeOperator,
    toggleLinkTicket,
    setSortedColumn,
    toggleTicketDialog,
    setDialogView,
    setSelectedTicketSlice,
    setInboxSideBarExpand,
    setInboxMobileSideBarExpand,
    openTicketForm

} = stateSlice.actions

export default stateSlice.reducer
