import React from "react";
import acronym from "utils/acronym";
import useTwColorByName from "utils/hooks/useTwColorByName";
import Avatar from "./Avatar";
import { USER_SYS } from "constants/app.constant";


const AvatarProfile = ({ user: userProp, sysUser = false, showFullName = false, size, shape, className }) => {

    const user = {
        name: sysUser ? USER_SYS.name : userProp?.name ?? 'Usuario',
        lastname: sysUser ? USER_SYS.lastname : userProp?.lastname ?? 'no encontrado',
        image: sysUser ? USER_SYS.image : userProp?.image
    }

    const { name = "", lastname = "" } = user

    let imgSrc = user.image ? user.image.split("?")[1] : null
    let image = imgSrc === 'file=' ? null : user.image


    const fullName = lastname ? name + " " + lastname : name
    
    const bgColor = useTwColorByName()

    const defaultAvatarProps = {
        shape: shape || 'circle',
        size: size || 30,
    }

    return (

        <>
            <Avatar
                className={`${image ? '' : bgColor(fullName)} ${className}`}
                src={image}
                {...defaultAvatarProps}
            >
                {acronym(fullName)}
            </Avatar>
            {showFullName && <span className="m-2 font-semibold text-gray-900 dark:text-gray-100">{fullName}</span>}
        </>

    )

}

export default AvatarProfile